import { Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';
import { UsersService } from './business-services/users.service';
import { unpackRules } from '@casl/ability/extra';
import { Ability } from '@casl/ability';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends TcService {
  constructor(private readonly usersService: UsersService) {
    super();
  }

  public async getPermissions(): Promise<any> {
    return await this.usersService.getPermissions();
  }
}
