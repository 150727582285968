import { Injectable, Optional } from '@angular/core';
import { TcBreezeService } from '@tc/breeze';
import { TcDataProviderConfig } from '@tc/abstract';
import { ITcDataService } from '@tc/abstract';
import { TcBreezeDataService } from '@tc/breeze';

/**
 * Create a basic, generic entity data service
 * suitable for persistence of most entities.
 * Assumes a common REST-y web API
 */
@Injectable()
export class TcDataServiceFactory {
  constructor(private breeze: TcBreezeService) {}

  /**
   * Create a default {ITcDataService} for the given entity type
   * @param storeKey {string} Name of the entity type for this data service
   */
  create<T>(
    storeKey: string,
    dataProvider: TcDataProviderConfig
  ): ITcDataService<T> {
    return new TcBreezeDataService<T>(storeKey, this.breeze, dataProvider);
  }
}
