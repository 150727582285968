import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { TcFormlyTemplateOptions } from '../../../abstract/tc-formly-template-options';

@Component({
  selector: 'tc-formly-date-picker',
  templateUrl: './tc-formly-date-picker.component.html',
})
export class TcFormlyDatePicker extends FieldType implements OnInit, OnDestroy {
  @ViewChild('tcInput') tcInput: MatInput;

  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;

  @ViewChild('calendarTrigger', { static: true })
  calendarTrigger: TemplateRef<any>;
  /**
   * This internal date is used for UI section. The UI needs a moment() object type to work properly
   * and we keep this object in this new field. The original formControl will return an ISO string date
   */
  internalDate: moment.Moment;

  /**
   * For unsubscribing
   */
  private formControlSubscription: Subscription;

  private inputCLearedSubscription: Subscription;

  ngOnInit() {
    // If there is no icon specified we set the icon to the default material date picker icon
    if (!this.to.matIcon && !this.to.faIcon) this.to.matIcon = 'today';

    // Set suffinx with setTimeout because there is no other way to set suffix
    // The this.formControl.value is also not ready so the internalDate needs to also be set in setTimeout
    setTimeout(() => {
      this.to._matSuffix = this.calendarTrigger;

      if (this.formControl.value)
        this.internalDate = moment(new Date(this.formControl.value));
    });

    this.formControlSubscription = this.formControl.valueChanges.subscribe(
      (value) => this.checkIfLabelShouldFloat(value)
    );

    this.inputCLearedSubscription = (
      this.to as TcFormlyTemplateOptions
    ).inputCleared$?.subscribe((_: Symbol) => {
      this.internalDate = null;
      this.tcInput.value = null;
    });
  }

  /**
   * If the user inputs a random string such as "dascsds" or any invalid value the formControl value will be null
   * If the formControl value is null we don't not want the label to come down so we have to manualy set it to stay up.
   */
  public checkIfLabelShouldFloat(value: any) {
    if (value) {
      this.formField.floatLabel = 'always';
    } else {
      this.formField.floatLabel = 'auto';
    }
  }

  ngOnDestroy() {
    this.formControlSubscription?.unsubscribe();
    this.inputCLearedSubscription?.unsubscribe();
  }

  updateInternalDateAndEmitEvent(newDateValue: moment.Moment | null): void {
    const setFormContolValue = (value) => {
      const hasNewValue = value != this.formControl.value;
      this.formControl.setValue(value, { emitEvent: hasNewValue });
    };

    // Check if the value is null because formatting a null value will result in a "Invalid data" value.
    if (!newDateValue) {
      setFormContolValue(newDateValue);
    } else {
      let formatedDate;
      if (this.to.isoDate) {
        formatedDate = newDateValue?.toISOString();
      } else {
        formatedDate = moment(newDateValue).format('YYYY-MM-DD');
      }

      setFormContolValue(formatedDate);
    }

    this.formControl.markAsTouched();
  }
}
