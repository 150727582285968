import {
  Injectable,
  ErrorHandler,
  Injector,
  Inject,
  isDevMode,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TcLoggerService } from '../services/tc-logger.service';
import { TcNotificationService } from '../services/tc-notification.service';
import { TcSpinnerService } from '../../tc-store/services/tc-spinner.service';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class TcErrorHandler implements ErrorHandler {
  constructor(@Inject(Injector) private injector: Injector) {}

  private get logger(): TcLoggerService {
    return this.injector.get(TcLoggerService);
  }

  private get notification(): TcNotificationService {
    return this.injector.get(TcNotificationService);
  }

  private get spinner(): TcSpinnerService {
    return this.injector.get(TcSpinnerService);
  }

  handleError(error: Error | HttpErrorResponse) {
    if (error) {
      if (error.message) {
        if (
          !error.message.includes('ServiceWorker') &&
          !error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')
        ) {
          if (error instanceof Error) {
            // Reset spinner so that the user can continue navigating the app
            this.spinner.resetSpinner();

            // Show error message
            const errMessage = error.message;
            this.showError(errMessage);
          }
        }
      }
      this.logger.error('An error occurred!', error);
    }
  }

  showError(errMessage: string) {
    if (isDevMode()) {
      this.notification.error(errMessage, null, {
        closeButton: true,
        timeOut: 0,
      });
    } else {
      this.notification.error(errMessage);
    }
  }
}
