import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-tc-formly-field-button',
  templateUrl: './tc-formly-field-button.component.html',
  styleUrls: ['./tc-formly-field-button.component.scss'],
})
export class TcFormlyFieldButtonComponent extends FieldType implements OnInit {
  matIcon = null;
  faIcon = null;
  isDisabled = false;

  ngOnInit() {
    this.matIcon = this.to.matIcon || this.matIcon;
    this.faIcon = this.to.faIcon || this.faIcon;
    this.isDisabled = this.to.disabled || this.isDisabled;
  }

  onClick($event) {
    if (this.to.onClick) {
      this.to.onClick($event);
    }
  }
}
