export const ConfigKeys = {
  API_BASE_PATH: 'API_BASE_PATH',
  jwt_renewal: 'jwt_renewal',
  idle_timeout: 'idle_timeout',
  logout_timeout: 'logout_timeout',
  logout_disabled: 'logout_disabled',
  translate: {
    language: 'translate.language',
  },
  logger: {
    level: 'logger.level',
    serverLogLevel: 'logger.serverLogLevel',
    serverLoggingUrl: 'logger.serverLoggingUrl',
  },
  notifications: {
    closeButton: 'notifications.closeButton',
    timeOut: 'notifications.timeOut',
  },
  layoutConfig: {
    responsiveOptions: 'layoutConfig.responsiveOptions',
    logoImageName: 'layoutConfig.logoImageName',
  },
  appTitle: 'appTitle',
  defaultPageSize: 'defaultPageSize',
  defaultAutocompleteMinLength: 'defaultAutocompleteMinLength',
  filterInputMinLength: 'filterInputMinLength',
  defaultFileUploaderFileTypes: 'defaultFileUploaderFileTypes',
  defaultForbiddenRoute: 'defaultForbiddenRoute',
  helpConfig: 'helpConfig',
  useWindowsLogin: 'useWindowsLogin'
};
