import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import {
  addInteractionForInterlocuteur,
  gridAddInteractionForInterlocuteur,
  printInterlocuteur,
} from './interlocuteurs.actions';
import { Store } from '@ngrx/store';
import { openTcGridDetailsPopup, closeTcGridDetailsDialog } from '@tc/core';
import {
  AddInteractionWithInterlocuteurPayload,
  GridAddInteractionWithInterlocuteurPayload,
  PrintInterlocuteurPayload,
} from './interlocuteurs.payloads';
import { initTcListDataStore } from '@tc/data-store';
import { TcConfigTypes, TcDataProviderType } from '@tc/abstract';
import { PrintService } from '../../../services/print.service';

@Injectable()
export class InterlocuteursEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly printService: PrintService
  ) {}

  openDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addInteractionForInterlocuteur),
        tap(async (payload: AddInteractionWithInterlocuteurPayload) => {
          const {
            currentStoreKey,
            nextStoreKey,
            detailsPopupComponent,
            defaultModel,
          } = payload;

          this.store$.dispatch(
            initTcListDataStore({
              storeKey: nextStoreKey,
              listConfig: {
                configType: TcConfigTypes.TcDataProvider,
                storeKey: nextStoreKey,
                dataProvider: {
                  configType: TcConfigTypes.TcDataProvider,
                  providerType: TcDataProviderType.BreezeJs,
                  dataSet: 'interactions',
                  fields:
                    'date,contactFaitParUtilisateur,intitule,presence,type,pieceJointe,compteRendu,autresParticipantsAlgoe',
                },
              },
            })
          );

          this.store$.dispatch(
            closeTcGridDetailsDialog({
              storeKey: currentStoreKey,
              callbackAction: openTcGridDetailsPopup,
              callbackActionPayload: {
                storeKey: nextStoreKey,
                detailsPopupComponent: detailsPopupComponent,
                data: { _id: undefined, ...defaultModel },
              },
            })
          );
        })
      ),
    { dispatch: false }
  );

  printInterlocuteur$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(printInterlocuteur),
        tap(async (payload: PrintInterlocuteurPayload) => {
          await this.printService.printInterlocuteur(payload.data);
        })
      ),
    { dispatch: false }
  );

  gridAddInteractionForInterlocuteur$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(gridAddInteractionForInterlocuteur),
        tap(async (payload: GridAddInteractionWithInterlocuteurPayload) => {
          const { storeKey, nextStoreKey, detailsPopupComponent, rowData } =
            payload;

          const interlocuteurModel = {
            presence: [
              {
                interlocuteur: {
                  _id: rowData._id,
                  nom: rowData.nom,
                  prenom: rowData.prenom,
                  titreCarteVisite: rowData.titreCarteVisite,
                },
                organisme: rowData.organisme,
                localisation: rowData.localisation,
              },
            ],
          };

          this.store$.dispatch(
            initTcListDataStore({
              storeKey: nextStoreKey,
              listConfig: {
                configType: TcConfigTypes.TcDataProvider,
                storeKey: nextStoreKey,
                dataProvider: {
                  configType: TcConfigTypes.TcDataProvider,
                  providerType: TcDataProviderType.BreezeJs,
                  dataSet: 'interactions',
                  fields:
                    'date,contactFaitParUtilisateur,intitule,presence,type,pieceJointe,compteRendu,autresParticipantsAlgoe',
                },
              },
              callbackAction: openTcGridDetailsPopup,
              callbackActionPayload: {
                storeKey: nextStoreKey,
                detailsPopupComponent: detailsPopupComponent,
                data: { _id: undefined, ...interlocuteurModel },
              },
            })
          );
        })
      ),
    { dispatch: false }
  );
}
