import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TcTranslateService, TcFormComponent } from '@tc/core';

@Component({
  selector: 'app-interlocuteurs-filter',
  templateUrl: './interlocuteurs-filter.component.html',
  styleUrls: ['./interlocuteurs-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InterlocuteursFilterComponent extends TcFormComponent<any>
  implements OnInit {
  gridStoreKey = 'interlocuteurs-grid';

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }

  ngOnInit() {}
}
