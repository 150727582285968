import { Injectable } from '@angular/core';
import { TcConfigTypes, TcDataProviderType, ListOrder, FilterTypesEnum } from '../../../../../../libs/tc/core/src/tc-abstract';
import { TcBreezeDataService, TcBreezeService} from '../../../../../../libs/tc/core/src/tc-breeze';

/**
 * Service to get FonctionTypes from backend
 */
@Injectable()
export class FonctionTypesService {

  private readonly breezeDataService: TcBreezeDataService<any>;

  constructor(breezeService: TcBreezeService) {
    this.breezeDataService = new TcBreezeDataService('refFonctionTypes', breezeService, {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refFonctionTypes',
      fields: 'codeFonctionType,fonctionType,utilisable',
      sortOrder: { key: 'fonctionType', order: ListOrder.Asc },
      filter: {
        filters: [
          {
            key: 'utilisable',
            value: 'true',
            filterType: FilterTypesEnum.Equal,
          },
        ],
      },
    },)
  }

  /**
   * get all FonctionTypes from backend
   */
  public async getAll(){
    return this.breezeDataService.getData(0, 1000);
  }

}
