import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getAuthenticationState } from '../../modules/auth/store/auth.selectors';
import { GenericRoutes } from '../../shared/typings/generic-routes';
import {
  loginFailure,
  loginWindows,
  saveToken,
  verifyMfa,
} from '../auth/store/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store$: Store<TcAppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const loginExternSuccess =
      route.routeConfig.path === GenericRoutes.LoginOauth;
    if (loginExternSuccess) {
      this.onLoginExternSuccess(route.queryParams.token);
      return false;
    }

    const loginExternFailed =
      route.routeConfig.path === GenericRoutes.LoginOauthFailed;
    if (loginExternFailed) {
      this.onLoginExternFailed();
      return false;
    }

    return this.store$.pipe(
      select(getAuthenticationState),
      map((authenticationState) => {
        const userOpenLogin = state.url === `/${GenericRoutes.Login}`;
        const isUserAuthenticated = !!authenticationState.token;
        if (userOpenLogin) {
          return this.onUserOpenLogin(isUserAuthenticated);
        }

        const userOpenLoginMfa = state.url === `/${GenericRoutes.LoginMfa}`;
        const shouldLoginMfa = authenticationState.mfaOptions?.mfa;
        if (userOpenLoginMfa) {
          return this.onUserOpenLoginMfa(shouldLoginMfa);
        }

        if (!isUserAuthenticated) {
          this.router.navigate([`/${GenericRoutes.Login}`]);
          return false;
        }

        return true;
      })
    );
  }

  private onUserOpenLoginMfa(shouldLoginMfa: boolean) {
    if (!shouldLoginMfa) {
      this.router.navigate(['/']);
    }

    return shouldLoginMfa;
  }

  private onUserOpenLogin(isUserAuthenticated: boolean) {
    if (isUserAuthenticated) {
      this.router.navigate(['/']);
    }

    return !isUserAuthenticated;
  }

  private onLoginExternFailed() {
    this.store$.dispatch(
      loginFailure({ error: new Error('login-form.errors.login-failed') })
    );

    this.router.navigate([`/${GenericRoutes.Login}`]);
  }

  private onLoginExternSuccess(token: string) {
    this.store$.dispatch(saveToken({ token }));
    this.store$.dispatch(verifyMfa());
  }
}
