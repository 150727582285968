import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FilterTypesEnum,
  ListOrder,
  MaterialColor,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import {
  closeAndOpenAnotherDialogButtonClicked,
  navigate,
  openFilteredDataDetail,
  TcSmartGridComponent,
} from '@tc/advanced-components';
import { MaterialButtonType } from '@tc/buttons';
import {
  TcGridCellComponent,
  TcTranslateService,
  TcGridColumnType,
} from '@tc/core';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';
import moment from 'moment';
import {
  contactFaitParValueGetter,
  presenceValueGetter,
  typeValueGetter,
} from '../../smart/interactions-grid/interactions-grid.component';

@Component({
  selector: 'app-interactions-history-grid',
  templateUrl: './interactions-history-grid.component.html',
  styleUrls: ['./interactions-history-grid.component.scss'],
})
export class InteractionsHistoryGridComponent
  extends TcSmartGridComponent
  implements OnInit
{
  @Input() interactionId: string;
  @Input() interlocuteurs: any[];
  @Input() parentGridStoreKey: string;

  storeKey = 'interactions-history-grid';

  constructor(
    private readonly translate: TcTranslateService,
    public readonly store$: Store<any>
  ) {
    super(store$);

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      gridOptions: {},
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'interactions',
        fields:
          'date,contactFaitParUtilisateur,intitule,presence,type,pieceJointe,compteRendu,autresParticipantsAlgoe',
      },
      columns: [
        {
          field: 'date',
          type: TcGridColumnType.Date,
          minWidth: 85,
          maxWidth: 95,
          sort: ListOrder.Desc,
          valueFormatter: (params) => {
            return moment(params.data.date).format('DD/MM/YYYY');
          },
        },
        {
          field: 'contactFaitParUtilisateur',
          minWidth: 74,
          maxWidth: 74,
          headerClass: 'two-lines-text-wrap',
          valueGetter: (params) => contactFaitParValueGetter(params.data),
        },
        {
          field: 'presence',
          sortable: false,
          cellRenderer: TcGridCellComponent.LinkRenderer,
          valueGetter: (params) => presenceValueGetter(params.data),
          autoHeight: true,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Primary,
                type: MaterialButtonType.Icon,
                matIcon: 'open_in_new',
                action: navigate,
                actionPayload: {
                  route: `/${CustomRoutes.Interlocuteurs}`,
                  queryParams: (index) => [
                    {
                      paramName: '_id',
                      dataPropertyName: `presence.${index}.interlocuteur._id`,
                    },
                    {
                      paramName: 'nom',
                      dataPropertyName: `presence.${index}.interlocuteur.nom`,
                    },
                  ],
                  callbackAction: openFilteredDataDetail,
                  callbackActionPayload: {
                    storeKey: 'interlocuteurs-grid',
                    filterProperty: '_id',
                    detailsPopupComponent: 'InterlocuteursDetailsComponent',
                  },
                },
                permissionAction: PermissionAction.Details,
                permissionSubject: PermissionSubject.Interlocuteurs,
              },
            ],
          },
        },
        {
          field: 'intitule',
        },
      ],
      columnNumberPerDevice: {
        extraSmallDevice: 4,
      },
    };
  }

  ngOnInit() {
    // Set the interlocuteurs filter
    this.listConfig.dataProvider.filter = {
      filters: [
        {
          key: 'presence.interlocuteur._id',
          value: this.interlocuteurs.join(','),
          filterType: FilterTypesEnum.In,
        },
        {
          key: '_id',
          value: this.interactionId,
          filterType: FilterTypesEnum.NotEqual,
        },
      ],
    };

    // Set the action column
    this.listConfig.columns.push({
      field: 'actions',
      minWidth: 85,
      maxWidth: 95,
      cellRenderer: TcGridCellComponent.SmartButtonRenderer,
      cellRendererParams: {
        buttons: [
          {
            color: MaterialColor.Primary,
            matIcon: 'open_in_new',
            action: closeAndOpenAnotherDialogButtonClicked,
            type: MaterialButtonType.Icon,
            actionPayload: {
              parentGridStoreKey: this.parentGridStoreKey,
              detailsPopupComponent: 'InteractionsDetailsComponent',
            },
          },
        ],
      },
    });

    super.ngOnInit();
  }
}
