import { TcCoreModule } from '@tc/core';
import { TcPanelListModule } from '@tc/panel-list';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcSmartFormModule } from '@tc/smart-form';
import { AgGridModule } from 'ag-grid-angular';
import { InteractionsGridComponent } from './components/smart/interactions-grid/interactions-grid.component';
import { InteractionsDetailsComponent } from './components/dumb/interactions-details/interactions-details.component';
import { InteractionsFilterComponent } from './components/smart/interactions-filter/interactions-filter.component';
import { TcDialogModule } from '@tc/dialog';
import { InteractionsFeedFilterComponent } from './components/smart/interactions-feed-filter/interactions-feed-filter.component';
import { InteractionsHistoryGridComponent } from './components/dumb/interactions-history-grid/interactions-history-grid.component';
import { InteractionsService } from './services/interactions.service';
import { EffectsModule } from '@ngrx/effects';
import { InteractionsEffects } from './store/interactions.effects';

const components = [
  InteractionsGridComponent,
  InteractionsDetailsComponent,
  InteractionsFilterComponent,
  InteractionsFeedFilterComponent,
  InteractionsHistoryGridComponent,
];

const services = [
  InteractionsService
];

@NgModule({
  declarations: [...components],
  providers: [...services],
  imports: [
    CommonModule,
    TcCoreModule,
    TcPanelListModule,
    AgGridModule,
    TcSmartFormModule,
    TcDialogModule,
    EffectsModule.forFeature([InteractionsEffects]),
  ],
  exports: [...components, ...services],
})
export class InteractionsModule {}
