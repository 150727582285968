import { createReducer, on } from '@ngrx/store';
import { CustomState } from './custom.state';
import { fonctionTypesOptionsLoaded } from './custom.actions';
import { FonctionTypesOptionsLoadedPayload } from './custom.payloads';

/**
 * Initial NgRx Custom State
 */
export const initialCustomState: CustomState = { fonctionTypesOptions: null };

/**
 * set fonctionTypesOptions into state
 */
const fonctionTypesOptionsLoadedHandler = (
  state: CustomState,
  newState: FonctionTypesOptionsLoadedPayload
): CustomState => {

  return {
    ...state,
    fonctionTypesOptions: newState.fonctionTypesOptions,
  };
};

/**
 * ngRx Custom State Reducer
 */
export const customReducer = createReducer(
  initialCustomState,
  on(fonctionTypesOptionsLoaded, fonctionTypesOptionsLoadedHandler)
);
