import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PermissionsService } from '../../../services/permissions.service';
import { initMenu } from '../../menu/store/menu.actions';
import { loadTcPermissions, loadTcPermissionsSuccess } from '@tc/permissions';

@Injectable()
export class PermissionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly permissions: PermissionsService
  ) {}

  loadPermissions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcPermissions),
        tap(async () => {
          const permissions = await this.permissions.getPermissions();
          this.store$.dispatch(loadTcPermissionsSuccess({ permissions }));
        })
      ),
    { dispatch: false }
  );

  /**
   * after the permissions are set into the store, call the action to initialize the menu
   */
  loadTcPermissionsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcPermissionsSuccess),
        tap(() => {
          this.store$.dispatch(initMenu());
        })
      ),
    { dispatch: false }
  );
}
