import * as R from 'ramda';
import { NgRxTcGridState, TcGridState } from '../state';
import { createSelector } from '@ngrx/store';

/**
 * Get tc grid from store by store key
 */
export const getTcGridByStoreKey = (
  state: NgRxTcGridState,
  props: { storeKey: string }
) => R.prop(props.storeKey)(state);

/**
 * Get tc grid columns
 */
export const getTcGridColumns = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr([], 'columns')(state);
  }
);

/**
 * Get tc grid columns
 */
export const getTcGridColumnNumberPerDevice = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(null, 'columnNumberPerDevice')(state);
  }
);

/**
 * Get tc grid data
 */
export const getTcGridData = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr([], 'data')(state);
  }
);

/**
 * Get tc grid total
 */
export const getTcGridTotal = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(0, 'total')(state);
  }
);

/**
 * Get tc grid data provider
 */
export const getTcGridDataProvider = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(null, 'dataProvider')(state);
  }
);

/**
 *  Get tc grid filters
 */
export const getTcGridFilters = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr({}, 'filter')(state);
  }
);

/**
 *  Get tc grid take
 */
export const getTcGridTake = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(30, 'take')(state);
  }
);

/**
 *  Get tc grid skip
 */
export const getTcGridSkip = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(0, 'skip')(state);
  }
);

/**
 *  Get tc grid sort
 */
export const getTcGridSort = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr({}, 'sort')(state);
  }
);

/**
 *  Get tc grid options
 */
export const getTcGridOptions = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr({}, 'gridOptions')(state);
  }
);

/**
 *  Get infinite scroll percent
 */
export const getTcInfiniteScrollPercent = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(75, 'infiniteScrollPercent')(state);
  }
);

/**
 * get tc grid add button configuration
 */
export const getTcGridAddButton = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(null, 'addButton')(state);
  }
);

/**
 * get tc grid css class
 */
export const getTcGridCssClass = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(null, 'cssClass')(state);
  }
);

/**
 * get tc grid details dialog id
 */
export const getTcGridDetailsDialogId = createSelector(
  getTcGridByStoreKey,
  (state: TcGridState) => {
    return R.propOr(null, 'detailsDialogId')(state);
  }
);
