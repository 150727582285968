import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  addTcGridButtonClicked,
  deleteTcGridButtonClicked,
  editTcGridButtonClicked,
  navigate,
  openFilteredDataDetail,
  TcSmartGridComponent,
} from '@tc/advanced-components';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridCellComponent,
  TcTranslateService,
  TcGridColumnType,
  TcFormlyWrapper,
  openTcGridDetailsPopup,
  TcFilterCONSTANTS,
} from '@tc/core';
import {
  FilterTypesEnum,
  MaterialColor,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
  ListOrder,
} from '@tc/abstract';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { getDeleteButtonConfig, MaterialButtonType } from '@tc/buttons';
import { InteractionTypeEnum } from '../../../interfaces/interaction-types.enum';
import moment from 'moment';
import { getAuthenticatedUser } from '../../../../../../modules/auth/store/auth.selectors';
import { take, filter, distinctUntilChanged } from 'rxjs/operators';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  getTcData,
  getTcDataIsLoading,
  loadTcData,
  NgRxTcDataState,
  updateTcListDataStore,
} from '@tc/data-store';
import { Observable, Subscription } from 'rxjs';
import { hasValue } from '@tc/utils';
import { selectByKey, SetPageInfo } from '@tc/store';
import * as R from 'ramda';
import { InteractionsService } from '../../../services/interactions.service';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-interactions-grid',
  templateUrl: './interactions-grid.component.html',
  styleUrls: ['./interactions-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InteractionsGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  @Input()
  onTableauDeBord = false;

  @Input()
  storeKey = 'interactions-grid';

  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  currentUser: any;

  restricted: boolean;
  canDeletePermission: boolean;
  canUpdatePermission: boolean;
  canViewPermission: boolean;

  /**
   *  Data store
   */
  dataStore$: Observable<NgRxTcDataState>;

  rowDataSubscription: Subscription;

  constructor(
    private readonly translate: TcTranslateService,
    store$: Store<any>,
    private readonly interactionsService: InteractionsService,
    private readonly route: ActivatedRoute
  ) {
    super(store$);

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  getProvider(collection: string) {
    return {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: collection,
      separatedInlineCountCall: true,
      fields:
        'date,contactFaitParUtilisateur,intitule,presence,type,pieceJointe,compteRendu,autresParticipantsAlgoe',
    };
  }

  /**
   * Determine if the connected user can delete the row element inside the grid
   * @param params Data from the grid
   * @returns
   */
  canDelete(params): boolean {
    if (this.canDeletePermission) {
      // If the user is restricted, we only allow him to delete the card he created
      if (
        this.restricted &&
        this.currentUser._id !== params?.contactFaitParUtilisateur?._id
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Determine if the connected user can update the row element inside the grid
   * @param params Data from the grid
   * @returns
   */
  canUpdate(params): boolean {
    if (this.canUpdatePermission) {
      // If the user is restricted, we only allow him to update the card he created
      if (
        this.restricted &&
        this.currentUser._id !== params?.contactFaitParUtilisateur?._id
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Determine if the connected user can only view but not update the row element inside the grid
   * @param params Data from the grid
   * @returns
   */
  canOnlyView(params): boolean {
    if (this.canViewPermission) {
      if (this.restricted) {
        if (
          this.canUpdatePermission &&
          this.currentUser._id === params?.contactFaitParUtilisateur?._id
        ) {
          return false;
        }
      } else if (this.canUpdatePermission) {
        return false;
      }
      return true;
    }
    return false;
  }

  async ngOnInit() {
    this.setFilterConfig();

    await this.getCurrentUser();
    await this.getRestrictedStatus();
    await this.getUserPermissions();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'interactions-grid',
      gridOptions: {},
      dataProvider: this.getProvider('view.interactionsWithCount'),
      writeDataProvider: this.getProvider('interactions'),
      columns: [
        {
          field: 'date',
          type: TcGridColumnType.Date,
          minWidth: 85,
          maxWidth: 95,
          sort: ListOrder.Desc,
          valueFormatter: (params) => {
            return moment(params.data.date).format('DD/MM/YYYY');
          },
        },
        {
          field: 'contactFaitParUtilisateur.adInitiales',
          minWidth: 74,
          maxWidth: 74,
          headerClass: 'two-lines-text-wrap',
        },
        {
          field: 'presence',
          sortable: false,
          cellRenderer: TcGridCellComponent.LinkRenderer,
          valueGetter: (params) => presenceValueGetter(params.data),
          autoHeight: true,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Primary,
                type: MaterialButtonType.Icon,
                matIcon: 'open_in_new',
                action: navigate,
                actionPayload: {
                  route: `/${CustomRoutes.Interlocuteurs}`,
                  queryParams: (index) => [
                    {
                      paramName: '_id',
                      dataPropertyName: `presence.${index}.interlocuteur._id`,
                    },
                    {
                      paramName: 'nom',
                      dataPropertyName: `presence.${index}.interlocuteur.nom`,
                    },
                  ],
                  callbackAction: openFilteredDataDetail,
                  callbackActionPayload: {
                    storeKey: 'interlocuteurs-grid',
                    filterProperty: '_id',
                    detailsPopupComponent: 'InterlocuteursDetailsComponent',
                  },
                },
                permissionAction: PermissionAction.Details,
                permissionSubject: PermissionSubject.Interlocuteurs,
              },
            ],
          },
        },
        {
          field: 'intitule',
        },
        {
          field: '',
          minWidth: 75,
          maxWidth: 75,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Primary,
                matIcon: 'visibility',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'InteractionsDetailsComponent',
                },
                name: 'view',
                permissionCustom: this.canOnlyView.bind(this),
              },
              {
                color: MaterialColor.Primary,
                matIcon: 'edit',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'InteractionsDetailsComponent',
                },
                name: 'edit',
                permissionCustom: this.canUpdate.bind(this),
              },
              getDeleteButtonConfig(deleteTcGridButtonClicked, {
                name: 'delete',
                permissionCustom: this.canDelete.bind(this),
              }),
            ],
          },
        },
      ],
      addButton: {
        detailsPopupComponent: 'InteractionsDetailsComponent',
        action: addTcGridButtonClicked,
        permissionAction: PermissionAction.Create,
        permissionSubject: PermissionSubject.Interactions,
        showSecondaryAddButton: true,
      },
      columnNumberPerDevice: {
        extraSmallDevice: 4,
      },
    };

    this.listConfig.filterConfig = this.filterConfig;

    let checkLeaderData = false;
    if (this.onTableauDeBord) {
      if (this.isAdministrator()) {
        // No filter to apply
        this.setPageTitle('tableau-de-bord-page.administrator-title');
      } else if (this.isDirector()) {
        // Filter for director
        this.setBaseFilterToDirector();
        this.setPageTitle('tableau-de-bord-page.director-title');
      } else if (this.isCompanyDirector()) {
        // No filter to apply
        this.setPageTitle('tableau-de-bord-page.company-director-title');
      } else {
        // Filter for leader
        this.setBaseFilterToLeader();
        this.setPageTitle('tableau-de-bord-page.leader-title');

        checkLeaderData = true;
      }
    }

    super.ngOnInit();

    // Check the leader data. If there is no leader data, contact data will be retrived
    if (checkLeaderData) {
      // Wait for the tc-data to be initialized
      await selectByKey(getTcDataIsLoading, this.dataStore$, this.storeKey)
        .pipe(
          filter((v) => v === false),
          take(1)
        )
        .toPromise();

      // Get the current data that reflects the leader condition
      const data = await selectByKey(getTcData, this.dataStore$, this.storeKey)
        .pipe(
          filter((v) => !!v),
          take(1)
        )
        .toPromise();

      // if the data is empty it means that the user is not a leader
      if (data?.length === 0) {
        // Remove the default leader filter
        this.listConfig.dataProvider.filter.filters.pop();

        // Add the contact base filter
        this.setBaseFilterToContact();

        // Change page name
        this.setPageTitle('tableau-de-bord-page.contact-title');

        // Update the tc list data store (specifically: the filter on data provider)
        this.store$.dispatch(
          updateTcListDataStore({
            storeKey: this.listConfig.storeKey,
            listConfig: R.clone(this.listConfig),
          })
        );

        // load contact data
        this.store$.dispatch(
          loadTcData({
            storeKey: this.storeKey,
            skip: 0,
          })
        );
      }
    }

    this.rowDataSubscription = selectByKey(
      getTcData,
      this.dataStore$,
      this.storeKey
    ).subscribe((data) => {
      // if we have only one row in the grid, check if autoOpen param is in the URL
      // if autoOpen param is in the URL and the value is true => open the details popup for the existing row
      if (data.length === 1) {
        const queryParams = this.route.snapshot.queryParams;

        if (Object.keys(queryParams).length > 0) {
          const autoOpenParam = Object.entries(queryParams).find(
            (e) => e[0] === TcFilterCONSTANTS.AUTO_OPEN
          );

          if (
            autoOpenParam?.[1] &&
            (autoOpenParam?.[1] as string).toLowerCase() === 'true'
          ) {
            this.store$.dispatch(
              openTcGridDetailsPopup({
                storeKey: this.storeKey,
                detailsPopupComponent: 'InteractionsDetailsComponent',
                data: data[0],
              })
            );
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.rowDataSubscription?.unsubscribe();
  }

  setFilterConfig() {
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'presence.interlocuteur.nom',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  filterOn: [
                    'presence.interlocuteur.nom',
                    'presence.interlocuteur.prenom',
                  ],
                  filterMultiWord: true,
                },
                smColSpan: 8,
                mdColSpan: 8,
                lgColSpan: 6,
                xlColSpan: 5,
                xxlColSpan: 4,
                className: 'main-filter',
              }),
            ],
            colSpan: 12,
          }),
          formlyRow({
            fields: [
              formlyControl({
                key: '_id',
                type: TcFormlyComponent.FormlyInput,
                hide: true,
                templateOptions: {
                  filterType: FilterTypesEnum.Equal,
                },
              }),
              formlyControl({
                key: 'intitule',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {},
                smColSpan: this.onTableauDeBord ? 12 : 6,
                mdColSpan: this.onTableauDeBord ? 12 : 6,
                lgColSpan: this.onTableauDeBord ? 12 : 4,
                xlColSpan: this.onTableauDeBord ? 4 : 4,
                xxlColSpan: this.onTableauDeBord ? 4 : 4,
              }),
              formlyControl({
                key: 'presence.organisme.nom',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {},
                hide: this.onTableauDeBord,
                xlColSpan: 4,
                xxlColSpan: 4,
              }),
              formlyControl({
                key: 'date',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  filterType: FilterTypesEnum.DateGreaterThanOrEqual,
                },
                xsColSpan: 6,
                smColSpan: 3,
                mdColSpan: 3,
                lgColSpan: 2,
                xlColSpan: 2,
              }),
              formlyControl({
                key: 'date',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  filterType: FilterTypesEnum.DateLessThanOrEqual,
                },
                xsColSpan: 6,
                smColSpan: 3,
                mdColSpan: 3,
                lgColSpan: 2,
                xlColSpan: 2,
              }),
              formlyControl({
                key: 'presence.organisme.typologies.typologie',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {},
                hide: this.onTableauDeBord,
                lgColSpan: 5,
                xlColSpan: 2,
                xxlColSpan: 4,
              }),
              formlyControl({
                key: 'type',
                type: TcFormlyComponent.FormlySelect,
                templateOptions: {
                  filterType: FilterTypesEnum.Equal,
                  options: [
                    { value: 1, label: this.translate.instant('Presential') },
                    { value: 2, label: this.translate.instant('Remote') },
                  ],
                },
                smColSpan: this.onTableauDeBord ? 3 : 6,
                mdColSpan: this.onTableauDeBord ? 3 : 6,
                lgColSpan: this.onTableauDeBord ? 4 : 3,
                xlColSpan: 2,
              }),
              formlyControl({
                key: 'presence.interlocuteur.rang',
                type: TcFormlyComponent.FormlySelect,
                templateOptions: {
                  filterType: FilterTypesEnum.Equal,
                  options: [
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '', label: 'N/A' },
                  ],
                },
                hide: this.onTableauDeBord,
                xlColSpan: 3,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'presence.interlocuteur.utilisateurChefFile.adInitiales',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {},
                hide: this.onTableauDeBord,
                lgColSpan: 5,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'contactFaitParUtilisateur.adInitiales',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {},
                xsColSpan: 12,
                smColSpan: 3,
                mdColSpan: 3,
                lgColSpan: this.onTableauDeBord ? 4 : 3,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'presence.localisation.codePostal',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {},
                hide: this.onTableauDeBord,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'presence.localisation.ville',
                hide: this.onTableauDeBord,
                lgColSpan: 4,
                xlColSpan: 3,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'presence.localisation.departement.departement',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  filterInputMinLength: 1,
                },
                hide: this.onTableauDeBord,
                lgColSpan: 4,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'presence.localisation.departement.region',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {},
                hide: this.onTableauDeBord,
                lgColSpan: 4,
                xlColSpan: 4,
                xxlColSpan: 4,
              }),
            ],
            colSpan: 12,
            wrappers: [TcFormlyWrapper.ExpansionPanel],
            templateOptions: {
              label: 'filter-options',
              id: 'filter-expansion-panel',
            },
          }),
        ],
      }),
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false,
    };
  }

  private isAdministrator(): boolean {
    return this.currentUser.roles.map((role) => role.role).includes('ADM');
  }

  private isDirector(): boolean {
    return this.currentUser.roles.map((role) => role.role).includes('D');
  }

  private isCompanyDirector(): boolean {
    return this.currentUser.roles.map((role) => role.role).includes('DS');
  }

  private async getCurrentUser() {
    this.currentUser = await this.store$
      .pipe(
        select(getAuthenticatedUser),
        filter((v) => !!v),
        take(1)
      )
      .toPromise();
  }

  private async getRestrictedStatus() {
    // If the user has limited rights, i.e not admin
    this.restricted = await this.interactionsService.userIsRestricted();
  }

  private async getUserPermissions() {
    this.canDeletePermission = await this.interactionsService.can(
      PermissionAction.Delete,
      PermissionSubject.Interactions
    );

    this.canUpdatePermission = await this.interactionsService.can(
      PermissionAction.Update,
      PermissionSubject.Interactions
    );

    this.canViewPermission = await this.interactionsService.can(
      PermissionAction.Details,
      PermissionSubject.Interactions
    );
  }

  private setPageTitle(title) {
    this.store$.dispatch(
      new SetPageInfo({
        title,
      })
    );
  }

  private setBaseDataProviderFilter(baseFilter) {
    // If there is no filter create a base filter object
    if (!this.listConfig.dataProvider.filter)
      this.listConfig.dataProvider.filter = {};

    if (this.listConfig.dataProvider.filter.filters?.length > 0) {
      this.listConfig.dataProvider.filter.filters.push(baseFilter);
    } else {
      this.listConfig.dataProvider.filter.filters = [baseFilter];
    }
  }

  setBaseFilterToDirector() {
    const baseFilter = {
      key: 'presence.interlocuteur.utilisateurChefFile.adUS',
      value: this.currentUser.adUS,
      filterType: FilterTypesEnum.Equal,
    };

    this.setBaseDataProviderFilter(baseFilter);
  }

  setBaseFilterToLeader() {
    const baseFilter = {
      key: 'presence.interlocuteur.utilisateurChefFile.adInitiales',
      value: this.currentUser.initiales,
      filterType: FilterTypesEnum.Equal,
    };

    this.setBaseDataProviderFilter(baseFilter);
  }

  setBaseFilterToContact() {
    const baseFilter = {
      key: 'contactFaitParUtilisateur._id',
      value: this.currentUser._id,
      filterType: FilterTypesEnum.Equal,
    };

    this.setBaseDataProviderFilter(baseFilter);
  }
}

export function contactFaitParValueGetter(interaction) {
  const contactFaitPar = interaction.contactFaitParUtilisateur;

  return contactFaitPar?.adInitiales;
}

export function presenceValueGetter(interaction) {
  if (!interaction.presence || interaction.presence?.length === 0) {
    return '';
  }

  const result = [];
  interaction.presence.forEach((element) => {
    const fullName =
      element.interlocuteur.prenom + ' ' + element.interlocuteur.nom;
    const titreCatreVisite = element?.interlocuteur?.titreCarteVisite;
    const organisationName = element?.organisme?.nom;

    const array = [titreCatreVisite, organisationName];
    let socialStatus = array.filter((n) => !!n).join(' - ');
    socialStatus = socialStatus.length > 0 ? `(${socialStatus})` : '';

    result.push(
      `${fullName}  ${socialStatus}${
        element.interactionsCount ? ' - ' + element.interactionsCount : ''
      }`.trim()
    );
  });

  return result;
}

export function typeValueGetter(interaction, translateService) {
  const type = interaction.type;

  switch (type) {
    case InteractionTypeEnum.Presential: {
      return translateService.instant(
        InteractionTypeEnum[InteractionTypeEnum.Presential]
      );
    }
    case InteractionTypeEnum.Remote: {
      return translateService.instant(
        InteractionTypeEnum[InteractionTypeEnum.Remote]
      );
    }
    default: {
      return '';
    }
  }
}
