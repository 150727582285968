import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { can } from '@tc/permissions';
import { TcAppState } from '@tc/store';
import { take } from 'rxjs/operators';
import { getAuthenticatedUser } from '../../../../../../../frontend/src/app/modules/auth/store/auth.selectors';
import { PermissionAction } from '../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../typings/permission-subject.enum';

/**
 * Service to mutualize some methods for grid and detail components
 */
@Injectable()
export class InteractionsService {
  constructor(private readonly store$: Store<TcAppState>) {}

  /**
   * Helper function to check permissions
   * @param action
   * @param subject
   * @returns
   */
  public async can(action: PermissionAction, subject: PermissionSubject) {
    const canValue = await this.store$
      .select(can(action, subject))
      .pipe(take(1))
      .toPromise();
    return canValue;
  }

  /**
   * Method to check if the roles of the connected user are giving him restricted rights on the popup.
   * If the user does not have high privileges, he can only edit his own items and cannot change the contactFaitParUtilisateur field.
   * @returns boolean
   */
  public async userIsRestricted(): Promise<boolean> {
    const user = await this.store$
      .select(getAuthenticatedUser)
      .pipe(take(1))
      .toPromise();

    let restricted = false;
    const roles =
      user?.roles.length > 0 ? user.roles.map((item) => item.role) : [];
    const rolesToCheck = ['M', 'MS', 'CS', 'D', 'DS'];

    for (const roleId of rolesToCheck) {
      if (roles.includes(roleId)) {
        restricted = true;
        break;
      }
    }
    return restricted;
  }
}
