import { GridOptions } from 'ag-grid-community';
import {
  ColDefExtended,
  ColGroupDefExtended,
  ColumnNumberPerDevice,
  TcGridAddButtonConfig,
} from '@tc/abstract';
import { TcGridSort } from '../../interfaces/tc-grid.interface';

/**
 * Tc grid state key in store
 */
export const DEFAULT_TC_GRID_STATE_KEY = 'tc-grid';

/**
 * Tc grid state in store (all the grids will be added in store under tc-grid state key)
 */
export interface NgRxTcGridState {
  [key: string]: TcGridState;
}

/**
 * tc grid state
 */
export interface TcGridState<T extends object = object> {
  storeKey: string;
  columns: (ColDefExtended | ColGroupDefExtended)[];
  columnNumberPerDevice?: ColumnNumberPerDevice;
  sort: TcGridSort;
  gridOptions?: GridOptions;
  infiniteScrollPercent?: number;
  addButton?: TcGridAddButtonConfig;
  cssClass?: string;
  detailsDialogId?: string;
}
