import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MaterialColor, TcConfigTypes, TcDataProviderType } from '@tc/abstract';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import {
  formlyControl,
  TcFormComponent,
  TcFormlyComponent,
  TcTranslateService,
} from '@tc/core';
import { initTcListDataStore } from '@tc/data-store';

@Component({
  selector: 'app-impersonate-popup',
  templateUrl: './impersonate-popup.component.html',
  styleUrls: ['./impersonate-popup.component.scss'],
})
export class ImpersonatePopupComponent extends TcFormComponent<any> implements OnInit {
  closeButtonConfig: TcSmartButton = {
    matIcon: 'close',
    type: MaterialButtonType.Icon,
    name: `prompt.close-button`,
  };

  cancelButtonConfig: TcSmartButton = {
    label: 'impersonate-popup.cancel',
    color: MaterialColor.Primary,
    name: `prompt.decline-button`,
  };

  confirmButtonConfig: TcSmartButton = {
    label: 'impersonate-popup.login-as',
    color: MaterialColor.Primary,
    type: MaterialButtonType.Raised,
    name: `prompt.confirm-button`,
  };

  loginAsFieldName = 'loginAs';
  autocompleteStoreKey = `impersonate-popup-${this.loginAsFieldName}`;

  constructor(
    private dialogRef: MatDialogRef<ImpersonatePopupComponent>,
    private store$: Store<any>,
    translate: TcTranslateService,
    elem: ElementRef
  ) {
    super(translate, elem);
  }

  ngOnInit() {
    const dataProvider = {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refUtilisateurs',
      fields: 'adInitiales, adLogin',
    };

    this.fields = [
      formlyControl({
        key: this.loginAsFieldName,
        label: this.translate.instant('impersonate-popup.field.loginAs'),
        type: TcFormlyComponent.TcAutoComplete,
        defaultValue: null,
        templateOptions: {
          autocompleteMinLength: 1,
          storeKey: this.autocompleteStoreKey,
          required: true,
          dataProvider: dataProvider,
          labelFieldName: 'adInitiales',
        },
      }),
    ];

    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.autocompleteStoreKey,
        listConfig: {
          storeKey: this.autocompleteStoreKey,
          dataProvider: dataProvider,
          configType: TcConfigTypes.TcFormField,
        },
      })
    );
  }

  onClose(result: boolean) {
    this.dialogRef.close(
      result ? this.model[this.loginAsFieldName].adLogin : null
    );
  }
}
