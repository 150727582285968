import { Component } from '@angular/core';
import { TcDumbComponent } from '@tc/abstract';
@Component({
  selector: 'app-interactions-feed-filter',
  templateUrl: './interactions-feed-filter.component.html',
  styleUrls: ['./interactions-feed-filter.component.scss'],
})
export class InteractionsFeedFilterComponent extends TcDumbComponent {
  panelStoreKey = 'interactions-panel';
}
