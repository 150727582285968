import { Component, Input } from '@angular/core';
import { TcDialogContentConfig, TcDumbComponent } from '@tc/abstract';

@Component({
  selector: 'tc-dialog-content',
  templateUrl: './tc-dialog-content.component.html',
  styleUrls: ['./tc-dialog-content.component.scss'],
})
export class TcDialogContentComponent extends TcDumbComponent {
  @Input() config: TcDialogContentConfig;
}
