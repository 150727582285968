export interface TcMenuItem {
  name: string;
  displayName?: string;
  disabled?: boolean;
  icon?: string;
  faIcon?: string;
  // children?: TcMenuItem[];
  hasSlideToggle?: boolean;
  isChecked?: boolean;
  hidden?: boolean;
}
