import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
  MaterialColor,
  ListOrder,
  FilterTypesEnum,
} from '@tc/abstract';
import { getDeactivateButtonConfig, MaterialButtonType } from '@tc/buttons';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridCellComponent,
  TcGridColumnType,
  TcFormlyWrapper,
} from '@tc/core';

import {
  addTcGridButtonClicked,
  deactivateTcGridButtonClicked,
  editTcGridButtonClicked,
  navigate,
  openFilteredDataDetail,
  TcSmartGridComponent,
  openEmailApp,
  openPhoneApp,
} from '@tc/advanced-components';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';
import { ranksOptions } from '../../../../../constants/ranks';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { getAbilities } from '@tc/permissions';
import { take } from 'rxjs/operators';

import moment from 'moment';
import { gridAddInteractionForInterlocuteur } from '../../../store/interlocuteurs.actions';
import { ActivatedRoute } from '@angular/router';
import { getFonctionTypesOptions } from '../../../../../store/custom.selectors';

@Component({
  selector: 'app-interlocuteurs-grid',
  templateUrl: './interlocuteurs-grid.component.html',
  styleUrls: ['./interlocuteurs-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InterlocuteursGridComponent
  extends TcSmartGridComponent
  implements OnInit
{
  storeKey = 'interlocuteurs-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];
  fonctionTypesOptions: any[];

  getProvider(collection: string, viewFields: string = null) {
    let fields =
      'codeInterlocuteur,nom,prenom,salutation,actif,email,telephoneFixe,telephonePortable,titreCarteVisite,donneesComplementaires,commentaire,rang,fonctionType,concerne,thematiquesSectorielle,organisme,utilisateurChefFile,autreContactsInternes,localisation,categories,pieceJointe,supprime';
    if (viewFields != null) {
      fields += ',' + viewFields;
    }
    return {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: collection,
      separatedInlineCountCall: true,
      fields: fields,
    };
  }

  constructor(
    store$: Store<any>,
  ) {
    super(store$);

    this.store$
    .pipe(select(getFonctionTypesOptions), take(1))
    .subscribe((options) => (this.fonctionTypesOptions = options));
  }

  async ngOnInit() {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'interlocuteurs-grid',
      gridOptions: {},
      dataProvider: this.getProvider(
        'view.interlocuteursWithLastContact',
        'lastContact'
      ),
      writeDataProvider: this.getProvider('interlocuteurs'),
      columns: [
        {
          field: '',
          minWidth: 75,
          maxWidth: 75,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                matIcon: 'mail',
                color: MaterialColor.Primary,
                type: MaterialButtonType.Icon,
                action: openEmailApp,
                actionPayload: {
                  emailField: 'email',
                },
                hidden: (data) => !data['email'],
              },
              {
                matIcon: 'phone',
                color: MaterialColor.Primary,
                type: MaterialButtonType.Icon,
                action: openPhoneApp,
                actionPayload: {
                  phoneField: 'telephonePortable',
                },
                hidden: (data) => !data['telephonePortable'],
              },
            ],
          },
        },
        {
          field: 'nom',
          valueGetter: 'data.nom.toUpperCase() + " " + data.prenom',
          sort: ListOrder.Asc,
          minWidth: 100,
        },
        {
          field: 'titreCarteVisite',
        },
        {
          field: 'organisme.nom',
          cellRenderer: TcGridCellComponent.LinkRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Primary,
                type: MaterialButtonType.Icon,
                matIcon: 'open_in_new',
                action: navigate,
                actionPayload: {
                  route: `/${CustomRoutes.Organismes}`,
                  queryParams: [
                    { paramName: '_id', dataPropertyName: 'organisme._id' },
                    { paramName: 'nom', dataPropertyName: 'organisme.nom' },
                  ],
                  callbackAction: openFilteredDataDetail,
                  callbackActionPayload: {
                    storeKey: 'organismes-grid',
                    filterProperty: '_id',
                    detailsPopupComponent: 'OrganismesDetailsComponent',
                  },
                },
                permissionAction: PermissionAction.Details,
                permissionSubject: PermissionSubject.Organismes,
              },
            ],
          },
        },
        {
          field: 'localisation.ville',
          valueGetter:
            '(data.localisation?.ville ?? "") + " " + (data.localisation?.departement?.departement ?? "")',
        },
        {
          field: 'email',
          cellRenderer: TcGridCellComponent.EmailRenderer,
        },
        {
          field: 'telephonePortable',
          cellRenderer: TcGridCellComponent.PhoneRenderer,
        },
        {
          field: 'utilisateurChefFile.adInitiales',
          minWidth: 75,
          maxWidth: 75,
        },
        {
          field: 'rang',
          type: [TcGridColumnType.Numeric, TcGridColumnType.Centered],
          minWidth: 85,
          maxWidth: 85,
        },
        {
          field: 'lastContact',
          headerClass: 'ag-date-header two-lines-text-wrap',
          type: TcGridColumnType.Date,
          valueFormatter: (params) => {
            return params.data.lastContact
              ? moment(params.data.lastContact).format('DD/MM/YYYY')
              : null;
          },
          minWidth: 90,
          maxWidth: 90,
        },
        {
          field: '',
          minWidth: 105,
          maxWidth: 105,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Primary,
                matIcon: abilities.can(
                  PermissionAction.Edit,
                  PermissionSubject.Interlocuteurs
                )
                  ? 'edit'
                  : 'visibility',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'InterlocuteursDetailsComponent',
                },
                permissionAction: PermissionAction.Details,
                permissionSubject: PermissionSubject.Interlocuteurs,
              },
              getDeactivateButtonConfig(
                deactivateTcGridButtonClicked,
                'supprime',
                true,
                {
                  permissionAction: PermissionAction.Deactivate,
                  permissionSubject: PermissionSubject.Interlocuteurs,
                }
              ),
              {
                color: MaterialColor.Primary,
                type: MaterialButtonType.Icon,
                matIcon: 'add_comment',
                action: gridAddInteractionForInterlocuteur,
                actionPayload: {
                  nextStoreKey: `interactions-grid`,
                  detailsPopupComponent: 'InteractionsDetailsComponent',
                },
                permissionAction: PermissionAction.Create,
                permissionSubject: PermissionSubject.Interactions,
              },
            ],
          },
        },
      ],
      addButton: {
        detailsPopupComponent: 'InterlocuteursDetailsComponent',
        action: addTcGridButtonClicked,
        permissionAction: PermissionAction.Create,
        permissionSubject: PermissionSubject.Interlocuteurs,
        showSecondaryAddButton: true,
      },
      filterConfig: this.getFilterConfig(),
      columnNumberPerDevice: {
        extraSmallDevice: 3,
      },
    };

    super.ngOnInit();
  }

  getFilterConfig(): TcSmartFilterConfig {
    return {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: 'nom',
                  templateOptions: {
                    filterOn: ['nom', 'prenom', 'email', 'telephonePortable'],
                    filterMultiWord: true,
                  },
                  smColSpan: 8,
                  mdColSpan: 8,
                  lgColSpan: 6,
                  xlColSpan: 6,
                  xxlColSpan: 4,
                  className: 'main-filter',
                }),
              ],
              colSpan: 12,
            }),

            formlyRow({
              fields: [
                formlyControl({
                  key: '_id',
                  type: TcFormlyComponent.FormlyInput,
                  hide: true,
                  templateOptions: {
                    filterType: FilterTypesEnum.Equal,
                  },
                }),
                formlyControl({
                  key: 'organisme.nom',
                  smColSpan: 7,
                  mdColSpan: 7,
                  lgColSpan: 4,
                  xlColSpan: 5,
                  xxlColSpan: 4,
                }),
                formlyControl({
                  key: 'rang',
                  type: TcFormlyComponent.FormlySelect,
                  templateOptions: {
                    filterType: FilterTypesEnum.Equal,
                    options: ranksOptions,
                  },
                  xsColSpan: 6,
                  smColSpan: 2,
                  mdColSpan: 2,
                  lgColSpan: 2,
                  xlColSpan: 1,
                  xxlColSpan: 1,
                }),
                formlyControl({
                  key: 'utilisateurChefFile.adInitiales',
                  xsColSpan: 6,
                  smColSpan: 3,
                  mdColSpan: 3,
                  lgColSpan: 2,
                  xlColSpan: 2,
                  xxlColSpan: 1,
                }),
                formlyControl({
                  key: 'titreCarteVisite',
                  xlColSpan: 4,
                  xxlColSpan: 4,
                }),
                formlyControl({
                  key: 'fonctionType',
                  type: TcFormlyComponent.FormlySelect,
                  templateOptions: {
                    options: this.fonctionTypesOptions,
                    filterType: FilterTypesEnum.Equal,
                  },
                }),
                formlyControl({
                  key: 'thematiquesSectorielle.nom',
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    filterType: FilterTypesEnum.In,

                    multiple: true,
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'refThematiquesSectorielles',
                      fields: 'nom',
                      distinct: true,
                      sortOrder: {
                        key: 'nom',
                        order: ListOrder.Asc,
                      },
                      filter: {
                        filters: [
                          {
                            key: 'utilisable',
                            value: true,
                            filterType: FilterTypesEnum.Equal,
                          },
                        ],
                      },
                    },
                  },
                  smColSpan: 12,
                  mdColSpan: 12,
                  lgColSpan: 5,
                  xlColSpan: 9,
                  xxlColSpan: 3,
                }),

                formlyControl({
                  key: 'localisation.codePostal',
                  lgColSpan: 3,
                  xlColSpan: 2,
                  xxlColSpan: 2,
                }),
                formlyControl({
                  key: 'localisation.ville',
                  lgColSpan: 3,
                  xlColSpan: 3,
                  xxlColSpan: 2,
                }),
                formlyControl({
                  key: 'localisation.departement.departement',
                  templateOptions: {
                    filterInputMinLength: 1,
                  },
                  xlColSpan: 3,
                  xxlColSpan: 2,
                }),
                formlyControl({
                  key: 'localisation.departement.region',
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    filterType: FilterTypesEnum.In,

                    multiple: true,
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'refDepartements',
                      fields: 'region',
                      distinct: true,
                      sortOrder: {
                        key: 'region',
                        order: ListOrder.Asc,
                      },
                      filter: {
                        filters: [
                          {
                            key: 'utilisable',
                            value: true,
                            filterType: FilterTypesEnum.Equal,
                          },
                        ],
                      },
                    },
                  },
                  smColSpan: 6,
                  mdColSpan: 6,
                  lgColSpan: 5,
                  xlColSpan: 4,
                  xxlColSpan: 3,
                }),
                // codeInterlocuteur,nom,prenom,salutation,actif,email,telephoneFixe,telephonePortable,titreCarteVisite,donneesComplementaires,commentaire,rang,fonctionType,concerne,thematiquesSectorielle,organisme,utilisateurChefFile,autreContactsInternes,localisation,categories,pieceJointe
              ],
              colSpan: 12,
              wrappers: [TcFormlyWrapper.ExpansionPanel],
              templateOptions: {
                label: 'filter-options',
              },
            }),
          ],
        }),
      ],
    };
  }
}
