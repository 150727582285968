import { MaterialColor } from '../../tc-abstract/component-configs/material-color.enum';
import { TcPermissionsConfig } from '../../tc-abstract/component-configs/tc-permissions-config';

/**
 * Angular material button types
 */
export enum MaterialButtonType {
  Raised = 'raised',
  Stroked = 'stroked',
  Flat = 'flat',
  Icon = 'icon',
  Fab = 'fab',
  MiniFab = 'mini-fab',
}

/**
 * tc-button config
 */
export interface TcSmartButton extends TcPermissionsConfig {
  /**
   * The text inside the button
   */
  label?: string;

  /**
   * Material icon. You can look for icons here:
   * https://jossef.github.io/material-design-icons-iconfont/
   */
  matIcon?: string;

  /**
   * Font awesome icon. You can look for icons here:
   * https://fontawesome.com/v5.15/icons?d=gallery&p=2&s=solid
   */
  faIcon?: string;

  /**
   * Material supports multiple button types. You can look at all the buttons
   * available at the bellow link and pick what you need:
   * https://material.angular.io/components/button/examples
   */
  type?: MaterialButtonType;

  /**
   * The color of the button
   */
  color?: MaterialColor;

  /**
   * What should the button do on click
   * If actionPayload is specified the button acts as an smart button and
   * dispatches it's action to the store.
   */
  action?: Function;

  /**
   * The payload of the action that will be dispatched
   * If actionPayload is specified the button acts as an smart button and
   * dispatches it's action to the store.
   */
  actionPayload?: any;

  /**
   * Selector for disabled state of a form
   */
  disableSelector?: any;

  /**
   * If this is specified the button changes it's disabled state
   * depending on disableSelector.
   */
  disableStoreKey?: string;

  /**
   * In order to send a default state key such as DEFAULT_TC_SMART_FORM_STATE_KEY
   * Used for dissable funcionality
   */
  smartStateKey?: string;
  // TODO: Maybe in the future : Add toolitip support?
  // tooltip?: string;
  // tooltipClass?: string;

  /**
   * Name of the button. For testing purposes.
   */
  name?: string;
}
