import { FormlyCustomValidators, TcComponentLookup } from '@tc/core';
import { Component, Inject } from '@angular/core';
import { TcSmartDetailPopupComponent } from '@tc/advanced-components';
import { getFonctionTypesOptions } from '../../../../../store/custom.selectors';

import {
  TcConfigTypes,
  TcDataProviderType,
  MaterialColor,
  ListOrder,
  FilterTypesEnum,
} from '@tc/abstract';
import { TcSmartButton } from '@tc/buttons';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcTranslateService,
} from '@tc/core';

import { submitAndCloseDialogButtonClicked } from '@tc/advanced-components';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormInvalidity,
} from '@tc/smart-form';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';

import {
  addInteractionForInterlocuteur,
  printInterlocuteur,
} from '../../../store/interlocuteurs.actions';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterlocuteursValidation } from './interlocuteurs-details-custom-validation';
import { ranksOptions } from '../../../../../constants/ranks';
import { Observable } from 'rxjs';
import { DEFAULT_TC_DATA_STATE_KEY, NgRxTcDataState } from '@tc/data-store';
import {
  distinctUntilChanged,
  filter,
  first,
  last,
  take,
} from 'rxjs/operators';
import { hasValue } from '@tc/utils';

@TcComponentLookup('InterlocuteursDetailsComponent')
@Component({
  selector: 'app-interlocuteurs-details',
  templateUrl: './interlocuteurs-details.component.html',
  styleUrls: ['./interlocuteurs-details.component.scss'],
})
export class InterlocuteursDetailsComponent extends TcSmartDetailPopupComponent {
  formStoreKey = 'interlocuteurs-grid';

  /**
   *  Data store
   */
  dataStore$: Observable<NgRxTcDataState>;

  fonctionTypesOptions: any[];

  constructor(
    private readonly translate: TcTranslateService,
    store$: Store<any>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    super(store$, data);

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.store$
      .pipe(select(getFonctionTypesOptions), take(1))
      .subscribe((options) => (this.fonctionTypesOptions = options));
  }

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.formStoreKey;

    const newInteractionButton = this.getNewInteractionButton();

    this.formConfig = {
      permissionAction: PermissionAction.Create,
      permissionSubject: PermissionSubject.Interlocuteurs,
      configType: TcConfigTypes.TcForm,
      storeKey: this.formStoreKey, //Maybe  + -detail
      autoSubmit: false,
      fieldConfigs: [],
      titleConfig: {
        storeKey: this.formStoreKey,
        configType: TcConfigTypes.TcDetailTitle,
        titlePrefix: 'interlocuteurs-grid.detail.ficheInterlocuteur',
        titleProperty: '_id',
        hasGenericSaveButton: true,
        genericSaveButtonAction: submitAndCloseDialogButtonClicked,
        buttonsList: [
          ...(this.data?._id
            ? [
                {
                  label: 'print',
                  color: MaterialColor.Primary,
                  matIcon: 'print',
                  action: printInterlocuteur,
                  actionPayload: {
                    data: this.data,
                  },
                },
              ]
            : []),
          ...(newInteractionButton ? [newInteractionButton] : []),
        ],
      },
      headerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        headerText: 'interlocuteurs-grid.detail.interlocuteurDetail',
      },
      footerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        headerText: this.data?._id
          ? 'interlocuteurs-grid.detail.interactions'
          : '',
        buttonsList: this.getHeaderButtons(),
      },
    };

    this.initFields();
  }

  initFields() {
    this.formConfig.fieldConfigs = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'supprime',
                defaultValue: false,
                hide: true,
              }),
              formlyControl({
                key: 'nom',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: true,
                  type: 'text',
                },
              }),
              formlyControl({
                key: 'prenom',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: true,
                  type: 'text',
                },
              }),
              formlyControl({
                key: 'salutation',
                type: TcFormlyComponent.FormlySelect,
                templateOptions: {
                  required: true,
                  type: 'number',
                  options: [
                    {
                      value: 0,
                      label: this.translate.instant(
                        'interlocuteurs.details.salutation.mr'
                      ),
                    },
                    {
                      value: 1,
                      label: this.translate.instant(
                        'interlocuteurs.details.salutation.mme'
                      ),
                    },
                  ],
                },
              }),
              formlyControl({
                key: 'email',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: false,
                  type: 'email',
                },
                validators: {
                  validation: [
                    (formControl) =>
                      InterlocuteursValidation.oneOfValidation(formControl),
                    FormlyCustomValidators.EmptyOrEmail,
                  ],
                },
              }),
              formlyControl({
                key: 'telephoneFixe',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: false,
                  type: 'text',
                },
                validators: [
                  (formControl) =>
                    InterlocuteursValidation.oneOfValidation(formControl),
                ],
              }),
              formlyControl({
                key: 'telephonePortable',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: false,
                  type: 'text',
                },
                validators: [
                  (formControl) =>
                    InterlocuteursValidation.oneOfValidation(formControl),
                ],
              }),
              formlyControl({
                key: 'utilisateurChefFile',
                type: TcFormlyComponent.TcAutoComplete,
                templateOptions: {
                  required: false,
                  showPromptIfNotSelected: true,
                  showPromptMessage:
                    'interlocuteurs.details.cdf.initiales.prompt',
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.BreezeJs,
                    dataSet: 'refUtilisateurs',
                    fields: '_id,adInitiales,adUS',
                  },
                  labelFieldName: 'adInitiales',
                },
                smColSpan: 3,
                mdColSpan: 3,
                lgColSpan: 2,
                xlColSpan: 2,
              }),
              formlyControl({
                key: 'rang',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: '',
                templateOptions: {
                  required: false,
                  options: ranksOptions,
                },
                smColSpan: 3,
                mdColSpan: 3,
                lgColSpan: 2,
                xlColSpan: 1,
                xxlColSpan: 1,
              }),
              formlyControl({
                key: 'titreCarteVisite',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: false,
                  type: 'text',
                },
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'fonctionType',
                type: TcFormlyComponent.FormlySelect,
                templateOptions: {
                  required: false,
                  type: 'array',

                  // ******* This is no longer a multiselect but in case we need it in the future I will leave it here ******
                  // Next 2 fields are necessary because we have type: 'array' and a value of type object in options list. The default comparer will check the references
                  // so we create a new comparer
                  // valueProp: (option) => option.value,
                  // compareWith: (o1, o2) =>
                  //   o1?.fonctionType === o2?.fonctionType,

                  options: this.fonctionTypesOptions,
                },
                xxlColSpan: 3,
              }),
              // OSC-152 Supprimer l'affichage de la thématique sectorielle dans l'interlocuteur sur OSCAR2
              // formlyControl({
              //   key: 'thematiquesSectorielle',
              //   type: TcFormlyComponent.TcSmartSelect,
              //   templateOptions: {
              //     required: false,
              //     multiple: true,
              //     compareWith: (o1, o2) => o1?.nom === o2?.nom,
              //     dataProvider: {
              //       configType: TcConfigTypes.TcDataProvider,
              //       providerType: TcDataProviderType.BreezeJs,
              //       dataSet: 'refThematiquesSectorielles',
              //       fields: 'nom',
              //       sortOrder: { key: 'nom', order: ListOrder.Asc },
              //       filter: {
              //         filters: [
              //           {
              //             key: 'utilisable',
              //             value: true,
              //             filterType: FilterTypesEnum.Equal,
              //           },
              //         ],
              //       },
              //     },
              //   },
              //   xlColSpan: 5,
              //   xxlColSpan: 3,
              // }),
              formlyControl({
                key: 'organisme',
                type: TcFormlyComponent.TcAutoComplete,
                templateOptions: {
                  required: false,
                  showPromptIfNotSelected: true,
                  showPromptMessage:
                    'interlocuteurs.details.organisme.nom.prompt',
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.BreezeJs,
                    dataSet: 'organismes',
                    filter: {
                      filters: [
                        {
                          key: 'supprime',
                          value: 'false',
                          filterType: FilterTypesEnum.Equal,
                        },
                      ],
                    },
                    fields: 'nom',
                  },
                  clearButtonEnabled: true,
                },
                xlColSpan: 4,
              }),
              formlyControl({
                key: 'localisation.adresse',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: false,
                  type: 'text',
                },
                xlColSpan: 4,
                xxlColSpan: 4,
              }),
              formlyControl({
                key: 'localisation.codePostal',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: false,
                  type: 'text',
                },
                validators: [
                  (formControl) =>
                    InterlocuteursValidation.oneOfValidation(formControl, true),
                ],
                xlColSpan: 2,
              }),
              formlyControl({
                key: 'localisation.ville',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: false,
                  type: 'text',
                },
                validators: [
                  (formControl) =>
                    InterlocuteursValidation.oneOfValidation(formControl, true),
                ],
              }),
              formlyControl({
                key: 'localisation.departement',
                type: TcFormlyComponent.TcAutoComplete,
                templateOptions: {
                  autocompleteMinLength: 1,
                  required: false,
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.BreezeJs,
                    dataSet: 'refDepartements',
                    fields: 'departement,region',
                    filter: {
                      filters: [
                        {
                          key: 'utilisable',
                          value: true,
                          filterType: FilterTypesEnum.Equal,
                        },
                      ],
                    },                    
                  },
                  labelFieldName: 'departement',
                },
                validators: [
                  (formControl) =>
                    InterlocuteursValidation.oneOfValidation(formControl, true),
                ],
              }),
            ],
          }),
          formlyColumn({
            fields: [
              formlyRow({
                fields: [
                  formlyControl({
                    key: 'concerne',
                    type: TcFormlyComponent.FormlySelect,
                    defaultValue: 0,
                    templateOptions: {
                      required: false,
                      options: [
                        {
                          value: 0,
                          label: this.translate.instant(
                            'interlocuteurs.details.concerne.algoeConsultants'
                          ),
                        },
                        {
                          value: 1,
                          label: this.translate.instant(
                            'interlocuteurs.details.concerne.algoeExecutive'
                          ),
                        },
                        {
                          value: 2,
                          label: this.translate.instant(
                            'interlocuteurs.details.concerne.commun'
                          ),
                        },
                      ],
                    },
                    smColSpan: 4,
                    mdColSpan: 4,
                    lgColSpan: 3,
                    xlColSpan: 3,
                    xxlColSpan: 3,
                  }),
                  formlyControl({
                    key: 'autreContactsInternes',
                    type: TcFormlyComponent.TcMultiSelect,
                    templateOptions: {
                      required: false,
                      dataProvider: {
                        configType: TcConfigTypes.TcDataProvider,
                        providerType: TcDataProviderType.BreezeJs,
                        dataSet: 'refUtilisateurs',
                        fields: 'adInitiales',
                        distinct: true,
                      },
                    },

                    smColSpan: 8,
                    mdColSpan: 6,
                    lgColSpan: 7,
                    xlColSpan: 7,
                    xxlColSpan: 8,
                  }),
                  formlyControl({
                    key: 'actif',
                    type: TcFormlyComponent.FormlyToggle,
                    defaultValue: true,
                    templateOptions: {
                      type: 'boolean',
                    },
                    mdColSpan: 2,
                    lgColSpan: 2,
                    xlColSpan: 2,
                    xxlColSpan: 1,
                  }),
                ],
              }),
              formlyRow({
                fields: [
                  formlyControl({
                    key: 'categories',
                    type: TcFormlyComponent.FormlySelect,
                    defaultValue: [{ categorie: 1 }],
                    hide: true,
                    templateOptions: {
                      required: false,
                      multiple: true,
                      type: 'array',

                      // Next 2 fields are necessary because we have type: 'array' and a value of type object in options list. The default comparer will check the references
                      // so we create a new comparer
                      valueProp: (option) => option.value,
                      compareWith: (o1, o2) => o1?.categorie === o2?.categorie,

                      options: [
                        {
                          value: { categorie: 1 },
                          label: this.translate.instant(
                            'interlocuteurs.details.categories.client'
                          ),
                        },
                        {
                          value: { categorie: 2 },
                          label: this.translate.instant(
                            'interlocuteurs.details.categories.prospect'
                          ),
                        },
                        {
                          value: { categorie: 3 },
                          label: this.translate.instant(
                            'interlocuteurs.details.categories.alumni'
                          ),
                        },
                        {
                          value: { categorie: 4 },
                          label: this.translate.instant(
                            'interlocuteurs.details.categories.autre'
                          ),
                        },
                      ],
                    },
                  }),
                ],
              }),
              formlyColumn({
                fields: [
                  formlyRow({
                    fields: [
                      formlyControl({
                        key: 'donneesComplementaires',
                        type: TcFormlyComponent.FormlyTextarea,
                        templateOptions: {
                          required: false,
                          type: 'text',
                          rows: 6,
                        },
                        lgColSpan: 6,
                        xlColSpan: 6,
                        xxlColSpan: 6,
                      }),
                      formlyControl({
                        key: 'commentaire',
                        type: TcFormlyComponent.FormlyTextarea,
                        templateOptions: {
                          required: false,
                          type: 'text',
                          rows: 6,
                        },
                        lgColSpan: 6,
                        xlColSpan: 6,
                        xxlColSpan: 6,
                      }),
                    ],
                  }),
                ],
                colSpan: 12,
              }),
              formlyRow({
                fields: [
                  formlyControl({
                    key: 'pieceJointe',
                    type: TcFormlyComponent.TcFileUploader,
                    templateOptions: {
                      required: false,
                      uploaderConfig: {
                        theme: 'inline',
                        multiple: true,
                        selectable: true,
                        fileDropDisabled: false,
                        maxSize: '1048576',
                        replaceTexts: {
                          selectFileBtn: 'Drop/Select Files',
                        },
                      },
                    },
                  }),
                ],
              }),
            ],
            wrappers: [TcFormlyWrapper.ExpansionPanel],

            templateOptions: {
              label: 'autres-details',
            },
            colSpan: 12,
          }),
        ],

        colSpan: 12,
      }),
    ];
  }

  getHeaderButtons(): TcSmartButton[] {
    const newInteractionButton = this.getNewInteractionButton();

    return [
      ...(newInteractionButton ? [newInteractionButton] : []),
      this.getSaveButton(),
    ];
  }

  getSaveButton(): TcSmartButton {
    return {
      label: 'enregister',
      color: MaterialColor.Primary,
      matIcon: 'save',
      action: submitAndCloseDialogButtonClicked,
      actionPayload: { storeKey: this.formStoreKey },
      disableStoreKey: this.formStoreKey,
      disableSelector: getTcSmartFormInvalidity,
      smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      permissionAction: PermissionAction.Create,
      permissionSubject: PermissionSubject.Interlocuteurs,
    };
  }

  getNewInteractionButton(): TcSmartButton {
    if (this.detailId) {
      const interlocuteurModel = {
        presence: [
          {
            interlocuteur: {
              _id: this.detailId,
              nom: this.data?.nom,
              prenom: this.data?.prenom,
              titreCarteVisite: this.data?.titreCarteVisite,
            },
            organisme: this.data?.organisme,
            localisation: this.data?.localisation,
          },
        ],
      };

      return {
        label: 'interlocuteurs-detail.nouvelle-interaction',
        color: MaterialColor.Primary,
        matIcon: 'add',
        action: addInteractionForInterlocuteur,
        actionPayload: {
          currentStoreKey: this.formStoreKey,
          nextStoreKey: `interactions-grid`,
          detailsPopupComponent: 'InteractionsDetailsComponent',
          defaultModel: interlocuteurModel,
        },
        smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      };
    }
  }
}
