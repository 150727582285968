export interface TcPromptDialogPayload {
  /**
   * The title of the prompt pop-up
   */
  title: string;

  /**
   * The text of the prompt pop-up can either be a translation key or a html text
   */
  text: string;

  /**
   * The text of the confirmation button. Default is 'yes'
   */
  confirm?: string;

  /**
   * Used in order to hide/show the confirm button. Used for notification prompts.
   * Default: true.
   */
  displayConfirmButton?: boolean;

  /**
   * The text of the cancel button. Default is 'no'
   */
  cancel?: string;

  /**
   * Used in order to hide/show the cancel button. Used for notification prompts.
   * Default: true.
   */
  displayCancelButton?: boolean;

  /**
   * In case the text property is actually a html. Or in case the text is already translated.
   */
  disableTextTranslation?: boolean;
}
