import { FormControl } from '@angular/forms';
import { FormlyCustomValidators } from '@tc/core';

export class InterlocuteursValidation {
  static oneOfValidation(formControl: FormControl, isNested?: true) {
    const formGroup = isNested
      ? formControl['_parent']['_parent']
      : formControl['_parent'];

    const emailFormControl = formGroup?.controls?.email;
    const telephoneFixeFormControl = formGroup?.controls?.telephoneFixe;
    const telephonePortableFormControl = formGroup?.controls?.telephonePortable;
    const localisationControls = formGroup?.controls?.localisation?.controls;

    const email = emailFormControl?.value;
    const telephoneFixe = telephoneFixeFormControl?.value;
    const telephonePortable = telephonePortableFormControl?.value;

    const codePostalFormControl = localisationControls?.codePostal;
    const villeFormControl = localisationControls?.ville;
    const departementFormControl = localisationControls?.departement;

    const codePostal = codePostalFormControl?.value;
    const ville = villeFormControl?.value;
    const departement = departementFormControl?.value;
    const departementId = departement?.id ?? departement?._id;
    const localisationIsStarted = !!codePostal || !!ville || !!departementId;
    const localisationIsComplete = !!codePostal && !!ville && !!departementId;
    const localisationIsValid =
      (!!localisationIsStarted && !!localisationIsComplete) ||
      !localisationIsStarted;

    const groupOfFieldsIsValid =
      !!email ||
      !!telephoneFixe ||
      !!telephonePortable ||
      !!localisationIsComplete;

    const removeEmailError = () => {
      // Check out if the email control has an email error already
      if (emailFormControl.getError(FormlyCustomValidators.EmptyOrEmail)) {
        emailFormControl.setErrors(
          { [FormlyCustomValidators.EmptyOrEmail]: true },
          { emitEvent: false }
        );
      } else {
        emailFormControl.setErrors(null, { emitEvent: false });
      }
    };

    if (groupOfFieldsIsValid) {
      removeEmailError();
      telephoneFixeFormControl.setErrors(null, { emitEvent: false });
      telephonePortableFormControl.setErrors(null, { emitEvent: false });
      codePostalFormControl.setErrors(null, { emitEvent: false });
      codePostalFormControl.setErrors(null, { emitEvent: false });
      villeFormControl.setErrors(null, { emitEvent: false });
      departementFormControl.setErrors(null, { emitEvent: false });
    }

    if (localisationIsStarted) {
      removeEmailError();
      telephoneFixeFormControl.setErrors(null, { emitEvent: false });
      telephonePortableFormControl.setErrors(null, { emitEvent: false });

      if (!codePostal) {
        codePostalFormControl.setErrors(
          { required: 'required' },
          { emitEvent: true }
        );
        codePostalFormControl.markAsTouched();
      }
      if (!ville) {
        villeFormControl.setErrors(
          { required: 'required' },
          { emitEvent: true }
        );
        villeFormControl.markAsTouched();
      }
      if (!departementId) {
        departementFormControl.setErrors(
          { required: 'required' },
          { emitEvent: true }
        );
        departementFormControl.markAsTouched();
      }
    }

    return (
      groupOfFieldsIsValid &&
      (formControl.valid || (isNested ? localisationIsValid : true))
    );
  }
}
