import { TcComponentLookup } from '@tc/core';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  submitAndCloseDialogButtonClicked,
  TcSmartDetailPopupComponent,
} from '@tc/advanced-components';
import {
  closeTcGridDetailsDialog,
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcTranslateService,
} from '@tc/core';
import { MaterialColor, TcConfigTypes, TcDataProviderType } from '@tc/abstract';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormInvalidity,
} from '@tc/smart-form';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { getAuthenticatedUser } from '../../../../../../../../../frontend/src/app/modules/auth/store/auth.selectors';
import { can } from '@tc/permissions';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';
import moment from 'moment';
import { InteractionsService } from '../../../services/interactions.service';
import { emailInteractionLink } from '../../../store/interactions.actions';

@TcComponentLookup('InteractionsDetailsComponent')
@Component({
  selector: 'app-interactions-details',
  templateUrl: './interactions-details.component.html',
  styleUrls: ['./interactions-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InteractionsDetailsComponent
  extends TcSmartDetailPopupComponent
  implements OnInit
{
  formStoreKey = 'interactions-grid';
  interlocuteurs: string[] = [];

  interactionDate = moment(this.data?.date).format('DD/MM/YYYY');

  constructor(
    private readonly translate: TcTranslateService,
    store$: Store<any>,
    private readonly interactionsService: InteractionsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(store$, data);

    this.formStoreKey = data.formStoreKey;

    this.interlocuteurs = this.data?.presence?.map(
      (presence) => presence?.interlocuteur?._id
    );
  }

  /**
   * Get authenticated user from store and compile the expected object for contactFaitParUtilisateur field.
   * @returns Object expected by the field with _id and adInitiales properties
   */
  public async getDefaultContact(): Promise<{
    _id: string | number;
    adInitiales: string;
  }> {
    const user = await this.store$
      .select(getAuthenticatedUser)
      .pipe(take(1))
      .toPromise();

    return {
      _id: user._id,
      adInitiales: user.initiales,
    };
  }

  private getInterlocutorsNames(): string {
    const names: string[] = [];
    for (const person of this.data?.presence) {
      const fullName = `${person.interlocuteur.nom} ${person.interlocuteur.prenom}`;

      fullName.trim();
      names.push(fullName);
    }
    return names.join(', ');
  }

  /**
   * Custom method to know if a user has the right to edit interaction (requires the connected user from store)
   * Exceptionally put here as it is a custom part of the permissions
   * @returns boolean
   */
  public async canEditInteraction(): Promise<boolean> {
    const dataId = this?.data?.contactFaitParUtilisateur?._id;

    if (dataId) {
      // Check if the user has the capability to update the form
      const canUpdate = await this.interactionsService.can(
        PermissionAction.Update,
        PermissionSubject.Interactions
      );
      if (canUpdate === false) {
        return false;
      }
    } else {
      // Check if the user has the capability to create the form
      const canCreate = await this.interactionsService.can(
        PermissionAction.Create,
        PermissionSubject.Interactions
      );
      if (canCreate === false) {
        return false;
      }

      return true;
    }

    // Determine if the user is restricted
    const restricted = await this.interactionsService.userIsRestricted();

    // If the user is restricted, we only allow him to change the card he created
    if (restricted) {
      const user = await this.store$
        .select(getAuthenticatedUser)
        .pipe(take(1))
        .toPromise();

      const userId = user._id;
      if (userId !== dataId) {
        return false;
      }
    }
    return true;
  }

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.formStoreKey;

    this.formConfig = {
      permissionCustom: await this.canEditInteraction(),
      configType: TcConfigTypes.TcForm,
      storeKey: this.formStoreKey, //Maybe  + -detail
      autoSubmit: false,
      fieldConfigs: [],
      titleConfig: {
        storeKey: this.formStoreKey,
        configType: TcConfigTypes.TcDetailTitle,
        titlePrefix: 'interactions-grid.detail.title',
        titleProperty: '_id',
        buttonsList: await this.getOtherButtons(),
        hasGenericSaveButton: true,
        genericSaveButtonAction: submitAndCloseDialogButtonClicked,
      },
      headerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        headerText: 'interactions-grid.detail.interactionDetail',
      },
      footerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        headerText: this.data?._id ? 'interactions-grid.detail.history' : '',
        buttonsList: await this.getHeaderButtons(),
      },
    };

    await this.initFields();
  }

  async initFields() {
    this.formConfig.fieldConfigs = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyRow({
                fields: [
                  formlyControl({
                    key: 'intitule',
                    type: TcFormlyComponent.FormlyInput,
                    templateOptions: {
                      required: true,
                      type: 'text',
                    },
                    smColSpan: 9,
                    mdColSpan: 9,
                    lgColSpan: 6,
                    xlColSpan: 6,
                    xxlColSpan: 6,
                  }),
                  formlyControl({
                    key: 'date',
                    type: TcFormlyComponent.TcDatePicker,
                    defaultValue: moment().format('YYYY-MM-DD'),
                    templateOptions: {
                      required: true,
                      type: 'text',
                    },
                    smColSpan: 3,
                    mdColSpan: 3,
                    lgColSpan: 2,
                    xlColSpan: 2,
                    xxlColSpan: 2,
                  }),
                  formlyControl({
                    key: 'type',
                    type: TcFormlyComponent.FormlySelect,
                    defaultValue: 1,
                    templateOptions: {
                      required: true,
                      options: [
                        {
                          value: 1,
                          label: this.translate.instant('Presential'),
                        },
                        {
                          value: 2,
                          label: this.translate.instant('Remote'),
                        },
                      ],
                    },
                    lgColSpan: 2,
                    xlColSpan: 2,
                    xxlColSpan: 2,
                  }),

                  formlyControl({
                    key: 'contactFaitParUtilisateur',
                    type: TcFormlyComponent.TcAutoComplete,
                    defaultValue: await this.getDefaultContact(),
                    templateOptions: {
                      required: true,
                      disabled:
                        await this.interactionsService.userIsRestricted(),
                      dataProvider: {
                        configType: TcConfigTypes.TcDataProvider,
                        providerType: TcDataProviderType.BreezeJs,
                        dataSet: 'refUtilisateurs',
                        fields: 'adInitiales',
                      },
                      labelFieldName: 'adInitiales',
                    },
                    lgColSpan: 2,
                    xlColSpan: 2,
                    xxlColSpan: 2,
                  }),
                ],
              }),
            ],
          }),

          formlyColumn({
            fields: [
              formlyRow({
                fields: [
                  formlyControl({
                    key: 'presence',
                    type: TcFormlyComponent.TcMultiSelect,
                    templateOptions: {
                      display: (item) => {
                        let label = '';
                        if (item) {
                          const name = `${item?.interlocuteur?.nom ?? ''} ${
                            item?.interlocuteur?.prenom ?? ''
                          }`;

                          name.trim();
                          label += name;

                          const socialStatusArray = [
                            item?.interlocuteur?.titreCarteVisite,
                            item?.organisme?.nom,
                          ];
                          const socialStatus = socialStatusArray
                            .filter((n) => !!n)
                            .join(' ');

                          label +=
                            socialStatus.length > 0 ? ` (${socialStatus})` : '';
                        }
                        return label;
                      },
                      required: true,
                      dataProvider: {
                        configType: TcConfigTypes.TcDataProvider,
                        providerType: TcDataProviderType.BreezeJs,
                        dataSet: 'view.presence',
                        fields: 'organisme, interlocuteur, localisation',
                        separatedInlineCountCall: true,
                      },
                      labelFieldName: 'interlocuteur.nom',
                      uniqueFieldName: 'interlocuteur._id',
                    },
                    smColSpan: 12,
                    mdColSpan: 12,
                    lgColSpan: 6,
                    xlColSpan: 6,
                    xxlColSpan: 6,
                  }),
                  formlyControl({
                    key: 'autresParticipantsAlgoe',
                    type: TcFormlyComponent.FormlyTextarea,
                    templateOptions: {
                      required: false,
                      type: 'text',
                      rows: 5,
                    },
                    smColSpan: 12,
                    mdColSpan: 12,
                    lgColSpan: 6,
                    xlColSpan: 6,
                    xxlColSpan: 6,
                  }),
                ],
              }),
            ],
          }),
          formlyRow({
            fields: [
              formlyControl({
                key: 'compteRendu',
                type: TcFormlyComponent.TcTextarea,
                templateOptions: {
                  required: false,
                  config: {
                    height: 400,
                  },
                },
                colSpan: 12,
              }),
            ],
            wrappers: [TcFormlyWrapper.GenericPanel],
            templateOptions: {
              label: this.translate.instant(
                'interactions-grid.labels.compteRendu'
              ),
            },
          }),
          formlyRow({
            fields: [
              formlyControl({
                key: 'pieceJointe',
                type: TcFormlyComponent.TcFileUploader,
                templateOptions: {
                  required: false,
                  uploaderConfig: {
                    theme: 'inline',
                    multiple: true,
                    selectable: true,
                    fileDropDisabled: false,
                    maxSize: '1048576',
                    replaceTexts: {
                      selectFileBtn: 'Drop/Select Files',
                    },
                  },
                },
                colSpan: 12,
              }),
            ],
          }),
        ],

        colSpan: 12,
      }),
    ];
  }

  async getHeaderButtons(): Promise<TcSmartButton[]> {
    return [await this.getSaveButton()];
  }

  async getSaveButton(): Promise<TcSmartButton> {
    return {
      label: 'enregister',
      color: MaterialColor.Primary,
      matIcon: 'save',
      action: submitAndCloseDialogButtonClicked,
      actionPayload: { storeKey: this.formStoreKey },
      disableStoreKey: this.formStoreKey,
      disableSelector: getTcSmartFormInvalidity,
      smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      permissionCustom: await this.canEditInteraction(),
    };
  }

  async getOtherButtons(): Promise<TcSmartButton[]> {
    if (this.data?.presence) {
      return [await this.getSendLinkButton()];
    } else {
      return [];
    }
  }

  async getSendLinkButton(): Promise<TcSmartButton> {
    return {
      label: 'send-link',
      color: MaterialColor.Primary,
      matIcon: 'email',
      action: emailInteractionLink,
      actionPayload: {
        interactionId: this.data?._id,
        autoOpen: true,
        interlocutors: this.getInterlocutorsNames(),
        interactionDate: this.interactionDate,
      },
      disableStoreKey: this.formStoreKey,
      disableSelector: getTcSmartFormInvalidity,
      smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
    };
  }
}
