import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FilterTypesEnum,
  ListOrder,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
} from '@tc/abstract';
import { getDeactivateButtonConfig, MaterialButtonType } from '@tc/buttons';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcGridCellComponent,
  TcGridSmartButtonParams,
} from '@tc/core';

import {
  addTcGridButtonClicked,
  deactivateTcGridButtonClicked,
  deleteTcGridButtonClicked,
  editTcGridButtonClicked,
  TcSmartGridComponent,
} from '@tc/advanced-components';
import { MaterialColor } from '@tc/abstract';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';
import { getAbilities } from '@tc/permissions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-organismes-grid',
  templateUrl: './organismes-grid.component.html',
  styleUrls: ['./organismes-grid.component.scss'],
})
export class OrganismesGridComponent
  extends TcSmartGridComponent
  implements OnInit
{
  storeKey = 'organismes-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  constructor(store$: Store<any>) {
    super(store$);

    this.setFilterConfig();
  }

  async ngOnInit() {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'organismes-grid',
      gridOptions: {},
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'organismes',
        filter: {
          filters: [
            {
              key: 'supprime',
              value: 'false',
              filterType: FilterTypesEnum.Equal,
            },
          ],
        },
        fields:
          'codeOrganisme,nom,compteClef,typologies,groupOrganisme,commentaire,pieceJointe,supprime',
      },
      columns: [
        {
          field: 'nom',
          sort: ListOrder.Asc,
        },
        {
          field: 'typologies',
          cellRenderer: TcGridCellComponent.ConcatArrayRenderer,
          cellRendererParams: {
            properyToDisplay: 'typologie',
          },
        },
        {
          field: 'compteClef.compteClef',
        },
        {
          field: 'groupOrganisme.nom',
        },
        {
          field: 'commentaire',
        },
        {
          field: '',
          minWidth: 85,
          maxWidth: 85,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Primary,
                matIcon: abilities.can(
                  PermissionAction.Edit,
                  PermissionSubject.Organismes
                )
                  ? 'edit'
                  : 'visibility',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'OrganismesDetailsComponent',
                },
                permissionAction: PermissionAction.Details,
                permissionSubject: PermissionSubject.Organismes,
              },
              getDeactivateButtonConfig(
                deactivateTcGridButtonClicked,
                'supprime',
                true,
                {
                  permissionAction: PermissionAction.Deactivate,
                  permissionSubject: PermissionSubject.Organismes,
                }
              ),
            ] as TcGridSmartButtonParams[],
          },
        },
      ],
      addButton: {
        detailsPopupComponent: 'OrganismesDetailsComponent',
        action: addTcGridButtonClicked,
        permissionAction: PermissionAction.Create,
        permissionSubject: PermissionSubject.Organismes,
        showSecondaryAddButton: true,
      },
      filterConfig: this.filterConfig,
    };

    super.ngOnInit();
  }

  setFilterConfig() {
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'nom',
                type: TcFormlyComponent.FormlyInput,
                lgColSpan: 4,
                xxlColSpan: 4,
                className: 'main-filter',
              }),
            ],
            colSpan: 12,
          }),
          formlyRow({
            fields: [
              formlyControl({
                key: '_id',
                type: TcFormlyComponent.FormlyInput,
                hide: true,
                templateOptions: {
                  filterType: FilterTypesEnum.Equal,
                },
              }),
              formlyControl({
                key: 'compteClef.compteClef',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  filterType: FilterTypesEnum.In,

                  multiple: true,
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.BreezeJs,
                    dataSet: 'refComptesClef',
                    fields: 'compteClef',
                    distinct: true,
                    sortOrder: {
                      key: 'compteClef',
                      order: ListOrder.Asc,
                    },
                    filter: {
                      filters: [
                        {
                          key: 'utilisable',
                          value: true,
                          filterType: FilterTypesEnum.Equal,
                        },
                      ],
                    },
                  },
                },
                lgColSpan: 4,
                xxlColSpan: 4,
              }),
              formlyControl({
                key: 'groupOrganisme._id',
                type: TcFormlyComponent.TcAutoComplete,
                templateOptions: {
                  filterType: FilterTypesEnum.Equal,
                  filterOptionsType: FilterTypesEnum.Contains,
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.BreezeJs,
                    filter: {
                      filters: [
                        {
                          key: 'supprime',
                          value: 'false',
                          filterType: FilterTypesEnum.Equal,
                        },
                      ],
                    },
                    dataSet: 'organismes',
                    fields: 'nom',
                  },
                  emitFields: '_id',
                },
                lgColSpan: 4,
                xxlColSpan: 4,
              }),
              formlyControl({
                key: 'typologies.typologie',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  filterType: FilterTypesEnum.In,

                  multiple: true,
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.BreezeJs,
                    dataSet: 'refTypologie',
                    fields: 'typologie',
                    distinct: true,
                    sortOrder: {
                      key: 'typologie',
                      order: ListOrder.Asc,
                    },
                    filter: {
                      filters: [
                        {
                          key: 'utilisable',
                          value: true,
                          filterType: FilterTypesEnum.Equal,
                        },
                      ],
                    },
                  },
                },
                lgColSpan: 4,
                xxlColSpan: 4,
              }),
            ],
            colSpan: 12,
            wrappers: [TcFormlyWrapper.ExpansionPanel],
            templateOptions: {
              label: 'filter-options',
            },
          }),
        ],
      }),
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false,
    };
  }
}
