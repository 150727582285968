import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { emailInteractionLink } from './interactions.actions';
import { EmailInteractionLinkPayload } from './interactions.payloads';
import { Clipboard } from '@angular/cdk/clipboard';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { Router } from '@angular/router';
import { TcFilterCONSTANTS, TcTranslateService } from '@tc/core';

@Injectable()
export class InteractionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private router: Router,
    private readonly translate: TcTranslateService,
  ) {}

  emailInteractionLink = createEffect(
    () =>
      this.actions$.pipe(
        ofType(emailInteractionLink),
        tap(async (payload: EmailInteractionLinkPayload) => {
          const { 
            interactionId, 
            autoOpen,
            interlocutors,
            interactionDate 
          } = payload;

          const platformUrl =
            this.router['location']._platformLocation.location.origin;

          const link = `${platformUrl}/${CustomRoutes.Interactions}?_id=${interactionId}%26${TcFilterCONSTANTS.AUTO_OPEN}=${autoOpen}`;
          
          const subject = this.translate.instant("interaction-detail-email.subject");
          const body = this.translate.instant(
            'globalMessages.interaction-detail-email.body', 
            { 
              interlocutors: interlocutors,
              interactionDate: interactionDate,
              link: link
            }
          );

          window.open(`mailto:?subject=${subject}&body=${body}`);
        })
      ),
    { dispatch: false }
  );
}
