import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ListOrder, TcDumbComponent } from '@tc/abstract';
import { getNestedValue } from '@tc/core';

/**
 * This is a renderer for fields that are arrays
 */
@Component({
  selector: 'tc-grid-concat-array-renderer',
  template: '{{cellValue}}',
})
export class TcGridConcatArrayRendererComponent
  extends TcDumbComponent
  implements AgRendererComponent
{
  public cellValue: string;

  agInit(params) {
    this.cellValue = this.getFormatedValueToDisplay(
      params.value,
      params.properyToDisplay,
      params.separator,
      params.sortOrder
    );
  }

  /**
   * @returns the formated value that will be display in the cell
   */
  getFormatedValueToDisplay(
    arrayOfObject: Array<any>,
    properyToDisplay: string,
    separator: string = ', ',
    sortOrder: ListOrder = ListOrder.Asc
  ): string {
    let formatedValue = '';

    if (!properyToDisplay)
      throw new Error(
        'You forgot to specify a properyToDisplay for TcGridCellComponent.ConcatArrayRenderer in cellRendererParams'
      );

    const sortedMappedArray = arrayOfObject
      ?.map((element) => getNestedValue(element, properyToDisplay))
      .sort();

    if (sortOrder === ListOrder.Desc) sortedMappedArray?.reverse();

    formatedValue = sortedMappedArray?.reduce(
      (previousValue, currentValue) =>
        `${previousValue}${separator}${currentValue}`
    );

    return formatedValue;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
