import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ImpersonateEffects } from './store/impersonate.effects';
import { ImpersonatePopupComponent } from './components/smart/impersonate-popup/impersonate-popup.component';
import { TcCoreModule } from '@tc/core';
import { TcSmartFormModule } from '@tc/smart-form';
import { TcButtonsModule } from '@tc/buttons';

const dialogs = [ImpersonatePopupComponent];

@NgModule({
  declarations: [...dialogs],
  imports: [
    CommonModule, 
    TcCoreModule, 
    TcSmartFormModule,
    TcButtonsModule,
    EffectsModule.forFeature([ImpersonateEffects])],
  exports: [...dialogs]
})
export class AuthModule {}
