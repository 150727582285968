import { Component, Input, isDevMode, OnInit } from '@angular/core';

import { TcDumbComponent } from '@tc/abstract';
import { MaterialButtonType } from '@tc/buttons';
import { TcDialogHeaderConfig } from '../../../tc-abstract/component-configs/tc-dialog-header-config';
import { closeTcGridDetailsDialog } from '@tc/core';

/**
 * Displays a title, a action buttons list, a title slot and two
 * actions slots that can be populated via portals
 */
@Component({
  selector: 'tc-dialog-header',
  templateUrl: './tc-dialog-header.component.html',
  styleUrls: ['./tc-dialog-header.component.scss'],
})
export class TcDialogHeaderComponent extends TcDumbComponent implements OnInit {
  @Input() dialogStoreKey: string;
  @Input() config: TcDialogHeaderConfig;

  /**
   * Checks to see if the component should render a close button
   */
  ngOnInit() {
    if (!this.dialogStoreKey && isDevMode())
      throw new Error('Missing dialog storeKey');

    if (this.config.hasCloseButton) {
      if (!this.config.buttonsList) this.config.buttonsList = [];
      this.config.buttonsList.push({
        matIcon: 'close',
        type: MaterialButtonType.Icon,
        action: closeTcGridDetailsDialog,
        actionPayload: { storeKey: this.dialogStoreKey },
        name: `${this.dialogStoreKey}.detail.close-button`,
      });
    }
  }
}
