export enum PermissionAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Edit = 'edit',
  Delete = 'delete',
  Deactivate = 'deactivate',
  List = 'list',
  Dashboard = 'dashboard',
  Details = 'details',
}
