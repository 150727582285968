import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcNavMenuNodes, TcPage } from '@tc/core';
import { getAbilities } from '@tc/permissions';
import { IPageState, SetPageInfo } from '@tc/store';
import { TcMediaService } from '@tc/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomRoutes } from '../../typings/custom-routes.enum';
import { PermissionAction } from '../../typings/permission-action.enum';
import { PermissionSubject } from '../../typings/permission-subject.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent extends TcPage implements OnInit, OnDestroy {
  constructor(
    private readonly store$: Store<any>,
    private readonly mediaService: TcMediaService
  ) {
    super(store$);
    this.isMobile$ = this.mediaService.isMobile;
  }

  isMobile$: Observable<boolean>;
  isMobileSubscription: Subscription;

  menuItems: TcNavMenuNodes[];

  responsiveOptions: any;

  async ngOnInit() {
    const pageInfo: IPageState = {
      title: 'home-page.title',
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();

    this.isMobileSubscription = this.isMobile$.subscribe((isMobile) => {
      if (isMobile) {
        // Update page's name
        this.store?.dispatch(new SetPageInfo(this.pageInfo));
      }
    });

    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    /**
     * NOTE: If this array gets changed probably the array form apps\frontend\src\app\custom\modules\menu\store\menu.effects.ts:42:7
     *       needs to be updated
     */
    this.menuItems = [
      {
        icon: 'forum',
        label: 'interactions-feed-page.title',
        route: CustomRoutes.InteractionsFeed,
        hidden: abilities.cannot(
          PermissionAction.List,
          PermissionSubject.Interactions
        ),
      },
      {
        icon: 'record_voice_over',
        label: 'interlocuteurs-page.title',
        route: CustomRoutes.Interlocuteurs,
        hidden: abilities.cannot(
          PermissionAction.List,
          PermissionSubject.Interlocuteurs
        ),
      },
      {
        icon: 'groups',
        label: 'organismes-page.title',
        route: CustomRoutes.Organismes,
        hidden: abilities.cannot(
          PermissionAction.List,
          PermissionSubject.Organismes
        ),
      },
      {
        icon: 'message',
        label: 'interactions-page.title',
        route: CustomRoutes.Interactions,
        hidden: abilities.cannot(
          PermissionAction.List,
          PermissionSubject.Interactions
        ),
      },
    ];
  }

  ngOnDestroy(): void {
    this.isMobileSubscription?.unsubscribe();
  }
}
