import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TcTranslateService, TcFormComponent } from '@tc/core';

@Component({
  selector: 'app-organismes-filter',
  templateUrl: './organismes-filter.component.html',
  styleUrls: ['./organismes-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrganismesFilterComponent
  extends TcFormComponent<any>
  implements OnInit
{
  gridStoreKey = 'organismes-grid';

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }

  ngOnInit() {}
}
