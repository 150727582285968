import { createReducer, on } from '@ngrx/store';
import { loadTcPermissionsSuccess } from './tc-permissions.actions';
import {
  initialTcPermissionsState,
  TcPermissionsState,
} from './tc-permissions.state';

export const tcPermissionsReducer = createReducer<TcPermissionsState>(
  initialTcPermissionsState,
  on(loadTcPermissionsSuccess, (state, { permissions }) => ({
    ...state,
    permissions,
  }))
);
