import { createAction, props } from '@ngrx/store';
import {
  AddInteractionWithInterlocuteurPayload,
  GridAddInteractionWithInterlocuteurPayload,
  PrintInterlocuteurPayload,
} from './interlocuteurs.payloads';

export const addInteractionForInterlocuteur = createAction(
  '[Interlocuteur] Add interaction for interlocuteur',
  props<AddInteractionWithInterlocuteurPayload>()
);

export const gridAddInteractionForInterlocuteur = createAction(
  '[Interlocuteur] Grid Add interaction for interlocuteur',
  props<GridAddInteractionWithInterlocuteurPayload>()
);

export const printInterlocuteur = createAction(
  '[Interlocuteur] Print',
  props<PrintInterlocuteurPayload>()
);
