import {
  Component,
  OnInit,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { EventTargetInterruptSource } from '@ng-idle/core';
import { TcAppComponent, TcConfigService, TcMediaService } from '@tc/core';
import { SessionTimeoutService } from './services/session-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends TcAppComponent implements OnInit {
  constructor(
    public readonly appConfigService: TcConfigService,
    private readonly sessionTimeoutService: SessionTimeoutService,
    private element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly mediaService: TcMediaService
  ) {
    super(appConfigService);
    this.sessionTimeoutService.start([
      new EventTargetInterruptSource(
        this.element.nativeElement,
        'mousedown keydown'
      ),
    ]);

    this.mediaService.init(this.changeDetectorRef);
  }

  async ngOnInit() {
    super.ngOnInit();
  }
}
