import { TcComponentLookup, openTcGridDetailsPopup } from '@tc/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { TcPanelItem } from '@tc/panel-list';
import { TcSmartComponent } from '@tc/abstract';
import { navigate } from '@tc/advanced-components';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { Store } from '@ngrx/store';
import { TcMediaService } from '@tc/core';
import { Observable } from 'rxjs';

@TcComponentLookup('InteractionsPanelItemComponent')
@Component({
  selector: 'app-interactions-panel-item',
  templateUrl: './interactions-panel-item.component.html',
  styleUrls: ['./interactions-panel-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InteractionsPanelItemComponent
  extends TcSmartComponent
  implements TcPanelItem
{
  data: any;
  storeKey: string;

  isExtraLargeDevice$: Observable<boolean>;

  constructor(
    readonly store$: Store<any>,
    private readonly mediaService: TcMediaService
  ) {
    super(store$);

    this.isExtraLargeDevice$ = this.mediaService.isExtraLargeDevice;
  }

  filterByInterlocuteur(interlocuteur) {
    this.store$.dispatch(
      navigate({
        storeKey: this.storeKey,
        route: `/${CustomRoutes.InteractionsFeed}`,
        queryParams: {
          'presence.interlocuteur._id': interlocuteur._id,
          'presence.interlocuteur.nom': interlocuteur.nom,
        },
        refreshRoute: true,
      })
    );
  }

  openInteractionDialog() {
    this.store$.dispatch(
      openTcGridDetailsPopup({
        storeKey: this.storeKey,
        detailsPopupComponent: 'InteractionsDetailsComponent',
        data: this.data,
      })
    );
  }
}
