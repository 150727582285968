import { Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';
import { TcVersion } from '../interfaces/tc-version.interface';
const {
  version,
  tcpVersion,
  buildNumber,
  buildDate,
  showBuildNumber,
} = require('../../../../../../package.json');

@Injectable({
  providedIn: 'any',
})
export class TcVersionService extends TcService {
  private getVersionData(): TcVersion {
    return {
      version: version,
      tcpVersion: tcpVersion,
      buildNumber: buildNumber,
      buildDate: buildDate,
      showBuildNumber: showBuildNumber,
    };
  }

  public displayVersionInConsole() {
    const versionData = this.getVersionData();
    const infos = `VERSION INFORMATIONS : Version : ${versionData.version} - Build number : ${versionData.buildNumber} - Build date : ${versionData.buildDate} - TCP version : ${versionData.tcpVersion}`;
    console.log(infos);
  }

  public getVersionAsString(): string {
    const versionData = this.getVersionData();
    let currentVersion = versionData.version;
    if (versionData.showBuildNumber) {
      currentVersion += '.' + versionData.buildNumber;
    }
    const versionString = `v${currentVersion} - ${versionData.buildDate}`;

    return versionString;
  }
}
