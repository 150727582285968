import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
@Component({
  selector: 'tc-layout',
  templateUrl: './tc-layout.component.html',
  styleUrls: ['./tc-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcLayoutComponent implements OnInit, OnDestroy {
  @Input()
  showToolbarMenuIcon = false;

  @Input()
  mode: 'side' | 'over' | 'push' = 'side';

  @Input()
  fixedInViewport: boolean;

  @Input()
  opened: boolean = true;

  @Input()
  autoFocus: false;

  @Input()
  disableClose: false;

  // gap between the top of the sidenav and the top of the viewport
  @Input()
  fixedTopGap: number;

  // gap between the bottom of the sidenav and the bottom of the viewport
  @Input()
  fixedBottomGap: number;

  @Input()
  position: 'start' | 'end' = 'start';

  @Input()
  collapsedMenu = false;

  /**
   * Object with the responsive configurations sent via the router.
   * It can be found in the configuration file of the custom app, assets/config.json
   * Object passed down from passed down the layout component
   * Current options:
   *  - responsive: Boolen (true || false)
   *  - breakPoint: String ('768px')
   *  - menuIcon: String ('more_vert')
   */
  @Input()
  responsiveOptions = {
    responsive: false,
    breakPoint: null,
  };

  /**
   * Boolean that is toggled in the layout component whenever the resolution reaches
   * the mobile breakpoint
   */
  @Input()
  mobileResolution: boolean = false;

  /**
   * Observable that catches the event that is sent by the layout component when one of
   * the menu items has been clicked.
   * Thus the sidenav knows to close itself after a menu is clicked when we are on a mobile resolution
   */
  @Input() selectedMenuItemEvent: Observable<void>;

  /**
   * Subscription to the selectedMenuItemEvent observable.
   * Only declared so that it can be removed in the ngOnDestroy method
   */
  private selectedMenuItemSubscription: Subscription;

  @Output()
  sideNavClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('drawer') public drawer: MatSidenav;

  ngOnInit() {
    if (this.responsiveOptions?.responsive) {
      this.selectedMenuItemSubscription = this.selectedMenuItemEvent.subscribe(
        () => {
          if (this.mobileResolution) {
            this.drawer.close();
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.responsiveOptions?.responsive) {
      this.selectedMenuItemSubscription.unsubscribe();
    }
  }

  onSideNavClick() {
    this.sideNavClick.emit();
  }
}
