import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganismesGridComponent } from './components/dumb/organismes-grid/organismes-grid.component';
import { TcCoreModule } from '@tc/core';
import { OrganismesFilterComponent } from './components/dumb/organismes-filter/organismes-filter.component';
import { OrganismesDetailsComponent } from './components/dumb/organismes-details/organismes-details.component';
import { TcButtonsModule } from '@tc/buttons';
import { TcSmartFormModule } from '@tc/smart-form';
import { InterlocuteursModule } from '../interlocuteurs/interlocuteurs.module';
import { TcDialogModule } from '@tc/dialog';
import { EffectsModule } from '@ngrx/effects';
import { OrganismesEffects } from './store/organismes.effects';

const components = [
  OrganismesFilterComponent,
  OrganismesGridComponent,
  OrganismesDetailsComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcCoreModule,
    InterlocuteursModule,
    TcDialogModule,
    EffectsModule.forFeature([OrganismesEffects]),
  ],
  exports: [...components],
})
export class OrganismesModule {}
