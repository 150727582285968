import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-interactions-filter',
  templateUrl: './interactions-filter.component.html',
  styleUrls: ['./interactions-filter.component.scss'],
})
export class InteractionsFilterComponent
  extends TcFormComponent<any>
  implements OnInit
{
  @Input()
  gridStoreKey = 'interactions-grid';

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }

  ngOnInit() {}
}
