import { LayoutComponent } from './../modules/layout/components/smart/layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../modules/guards/auth.guard';
import { TermsGuard } from '../modules/guards/terms.guard';
import { OrganismesPageComponent } from './pages/organismes-page/organismes-page.component';
import { InterlocuteursPageComponent } from './pages/interlocuteurs-page/interlocuteurs-page.component';
import { InteractionsFeedPageComponent } from './pages/interactions-feed-page/interactions-feed-page.component';
import { RouteResolver } from '../shared/resolvers/route.resolvers';
import { PermissionAction } from './typings/permission-action.enum';
import { PermissionSubject } from './typings/permission-subject.enum';
import { InteractionsPageComponent } from './pages/interactions-page/interactions-page.component';
import { TableauDeBordComponent } from './pages/tableau-de-bord/tableau-de-bord.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { CustomRoutes } from './typings/custom-routes.enum';
import { HomeComponent } from './pages/home-page/home.component';
import { FonctionTypesOptionsResolver } from './resolvers/fonction-types-options.resolver';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [{ path: '', component: HomeComponent, pathMatch: 'full' }],
    resolve: { route: RouteResolver },
    data: {
      // Same permissions must be applied here that the route of the dashboard component.
      // We need to check the connected user has the rights to consult the default landing page.
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.Dashboard,
          subject: PermissionSubject.Interactions,
        },
      ],
    },
  },
  {
    path: CustomRoutes.InteractionsFeed,
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: InteractionsFeedPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.List,
          subject: PermissionSubject.Interactions,
        },
      ],
    },
  },
  {
    path: CustomRoutes.Organismes,
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: OrganismesPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.List,
          subject: PermissionSubject.Organismes,
        },
      ],
    },
  },
  {
    path: CustomRoutes.Interlocuteurs,
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: InterlocuteursPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver, fonctionTypeOptions: FonctionTypesOptionsResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.List,
          subject: PermissionSubject.Interlocuteurs,
        },
      ],
    },
  },
  {
    path: CustomRoutes.Interactions,
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: InteractionsPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.List,
          subject: PermissionSubject.Interactions,
        },
      ],
    },
  },
  {
    path: CustomRoutes.TableauDeBord,
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: TableauDeBordComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.Dashboard,
          subject: PermissionSubject.Interactions,
        },
      ],
    },
  },
  {
    // Default permission failure landing page. Path must be the same as config key defaultForbiddenRoute.
    // We are not in a class, we cannot use configService to load the value.
    path: CustomRoutes.AccesNonAutorise,
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: ForbiddenPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomRoutingModule {}
