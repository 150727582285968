import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { TcDateRangeValue } from '../../../interfaces/tc-daterange-value';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tc-formly-date-range-picker',
  templateUrl: './tc-formly-date-range-picker.component.html',
})
export class TcFormlyDateRangePicker extends FieldType
  implements OnInit, OnDestroy {
  /**
   * Template used for the suffix for the mat-field
   */
  @ViewChild('actionButtons', { static: true }) actionButtons: TemplateRef<any>;

  /**
   * Directive used for opening the calendar
   */
  @ViewChild(DaterangepickerDirective, { static: true })
  pickerDirective: DaterangepickerDirective;

  /**
   * Format for how dates are dislayed
   */
  public format = 'DD/MM/YYYY';

  /**
   * The valueFormControl of the input
   * Note this is not equal to the formControl, the form control is set depending on
   * whether value is a proper value or not.
   */
  public valueFormControl = new FormControl();

  /**
   * For unsubscribing
   */
  valueFormControlSubscription: Subscription;

  ngOnInit() {
    // If there is no icon specified we set the icon to the default material date picker icon
    if (!this.to.matIcon && !this.to.faIcon) this.to.matIcon = 'date_range';

    // Set suffinx with setTimeout so that we don't get the Expression has changed after it was checked error
    setTimeout(() => {
      this.to._matSuffix = this.actionButtons;
      if (this.to.preventOpenFromClick) {
        this.preventOpenFromClick();
      }
    });

    /**
     * Called only when a date range is selected from the calendar
     */
    this.valueFormControlSubscription = this.valueFormControl.valueChanges.subscribe(
      () => {
        this.onChangeValue(this.valueFormControl.value);
      }
    );
  }

  /**
   * Called when the input value changes via date selection from the calendar. If the value is a proper
   * value it is then set into the form control, if not, the form control is reset to null.
   */
  public onChangeValue(value: TcDateRangeValue) {
    if (value?.start && value?.end) {
      this.formControl.setValue(value);
      this.onBlur();
    } else {
      this.formControl.reset(null);
    }
    this.checkIfLabelShouldFloat(value);
  }

  /**
   * Called when the input value changes via user keyboard., If the value is a proper
   * value it is then set into the form control, if not, the form control is reset to null.
   */
  public onChangeValueByKeyboad(value: string) {
    const [startValue, endValue] = value.split(' - ');

    const start = moment(startValue, this.format);
    const end = moment(endValue, this.format)
      .add(1, 'day')
      .subtract(1, 'second');

    if (start.isValid() && end.isValid()) {
      this.formControl.setValue({ start, end });
    } else {
      this.formControl.reset(null);
    }

    this.checkIfLabelShouldFloat(value);
  }

  /**
   * Called when the user clicks on the open calendar icon.
   */
  public openDatepicker(event) {
    this.pickerDirective.open(event);
  }

  /**
   * Called when the user clicks on the "X" icon that appears after a date is selected
   */
  public reset() {
    this.formControl.reset(null);
    this.valueFormControl.reset(null);
    this.checkIfLabelShouldFloat(null);
    setTimeout(this.onBlur.bind(this));
  }

  /**
   * Overwrite picker open method (https://github.com/fetrarij/ngx-daterangepicker-material/blob/dc55667870baa0c8968fed78a255ce0dcbeab48d/src/daterangepicker/daterangepicker.directive.ts#L240)
   * to prevent opening when a flag is not sent
   */
  private preventOpenFromClick() {
    const oldOpen = this.pickerDirective.open;
    this.pickerDirective.open = shouldOpen => {
      if (!shouldOpen) {
        return;
      }
      oldOpen.call(this.pickerDirective);
    };
  }

  /**
   * On blur event of the input
   */
  public onBlur() {
    this.formControl.markAsTouched();
    this.field.focus = false;
  }

  /**
   * If the user inputs a random string such as "dascsds" or any invalid value the formControl value will be null
   * If the formControl value is null we don't not want the label to come down so we have to manualy set it to stay up.
   */
  private checkIfLabelShouldFloat(value: any) {
    if (value) {
      this.formField.floatLabel = 'always';
    } else {
      this.formField.floatLabel = 'auto';
    }
  }

  ngOnDestroy() {
    this.valueFormControlSubscription?.unsubscribe();
  }
}
