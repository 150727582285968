import { TcCoreModule } from '@tc/core';
import { TcPanelListModule } from '@tc/panel-list';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { InteractionsPanelItemComponent } from './components/dumb/interactions-panel-item/interactions-panel-item.component';
import { InteractionsPanelComponent } from './components/dumb/interactions-panel/interactions-panel.component';
import { InterlocuteursGridComponent } from './components/smart/interlocuteurs-grid/interlocuteurs-grid.component';
import { InterlocuteursDetailsComponent } from './components/smart/interlocuteurs-details/interlocuteurs-details.component';
import { InterlocuteursFilterComponent } from './components/smart/interlocuteurs-filter/interlocuteurs-filter.component';
import { TcSmartFormModule } from '@tc/smart-form';
import { TcDialogModule } from '@tc/dialog';
import { EffectsModule } from '@ngrx/effects';
import { InterlocuteursEffects } from './store/interlocuteurs.effects';


const components = [
  InteractionsPanelItemComponent,
  InteractionsPanelComponent,
  InterlocuteursGridComponent,
  InterlocuteursDetailsComponent,
  InterlocuteursFilterComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    TcPanelListModule,
    AgGridModule,
    TcSmartFormModule,
    TcDialogModule,
    EffectsModule.forFeature([InterlocuteursEffects]),
  ],
  exports: [...components],
})
export class InterlocuteursModule {}
