import { NavigationStart, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { TcTranslateService } from '@tc/core';
import { take } from 'rxjs/operators';
import { clearUserData } from '../modules/auth/store/auth.actions';
import { getAuthenticationToken } from '../modules/auth/store/auth.selectors';
import { verifyTerms } from '../modules/terms-conditions/store/terms-conditions.actions';
import { Subscription } from 'rxjs';
import { ConfigService } from '../shared/services/config.service';
import { Title } from '@angular/platform-browser';
import { ConfigKeys } from '../shared/interfaces/config.interface';
import { GenericRoutes } from '../shared/typings/generic-routes';

@Injectable()
export class AppInitService {
  private routerSubscription: Subscription;
  private loginEndpointRoute: string = null;

  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly router: Router,
    private readonly translate: TcTranslateService,
    private config: ConfigService,
    private titleService: Title
  ) {}

  public async init() {
    this.setAppTitle();

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.setLoginEndpointRoute(event.url);
      }
    });

    const logedUserToken = await this.store$
      .select(getAuthenticationToken)
      .pipe(take(1))
      .toPromise();

    if (!logedUserToken) {
      return;
    }

    await this.verifyTermsConditions();
  }

  private async verifyTermsConditions() {
    this.store$.dispatch(clearUserData());
    this.store$.dispatch(verifyTerms());
  }

  private setLoginEndpointRoute(url: string) {
    const routesToIgnore = [
      `/${GenericRoutes.Login}`,
      `/${GenericRoutes.LoginMfa}`,
      `/${GenericRoutes.LoginOauth}`,
      `/${GenericRoutes.LoginOauthFailed}`,
      `/${GenericRoutes.TermsOfUse}`,
    ];

    if (routesToIgnore.includes(url)) {
      url = '/';
    }
    if (!this.loginEndpointRoute) {
      this.loginEndpointRoute = url;
      this.routerSubscription.unsubscribe();
    }
  }

  public getLoginEndPointRoute() {
    return this.loginEndpointRoute;
  }

  /**
   * Dinamically sets app title based on config.json
   */
  private setAppTitle() {
    const appTitle = this.config.get(ConfigKeys.appTitle) as string;
    this.titleService.setTitle(appTitle || 'App title not set');
  }
}
