import { tap } from 'rxjs/operators';
import { PrintService } from '../../../services/print.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { printOrganisme } from './organismes.actions';
import { PrintOrganismePayload } from './organismes.payloads';

@Injectable()
export class OrganismesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly printService: PrintService
  ) {}

  printOrganisme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(printOrganisme),
        tap(async (payload: PrintOrganismePayload) => {
          await this.printService.printOrganisme(payload.data);
        })
      ),
    { dispatch: false }
  );
}
