import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { IPageState } from '@tc/store';

@Component({
  selector: 'app-tableau-de-bord',
  templateUrl: './tableau-de-bord.component.html',
  styleUrls: ['./tableau-de-bord.component.scss'],
})
export class TableauDeBordComponent extends TcPage implements OnInit {
  constructor(private readonly store$: Store<any>) {
    super(store$);
  }

  storeKey = 'tableau-de-bord-grid';

  async ngOnInit(): Promise<any> {
    const pageInfo: IPageState = {
      title: 'tableau-de-bord-page.title',
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
