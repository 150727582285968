import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TcTranslateService } from '@tc/core';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { impersonate, loginAs } from '../../../../modules/auth/store/auth.actions';
import { MatDialog } from '@angular/material/dialog';
import { ImpersonatePopupComponent } from '../components/smart/impersonate-popup/impersonate-popup.component';

@Injectable()
export class ImpersonateEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<TcAppState>,
    private readonly translate: TcTranslateService,
    private readonly dialog: MatDialog,
  ) {}

  impersonate = createEffect(
    () =>
      this.actions$.pipe(
        ofType(impersonate),
        tap(() => {

          const dialogRef = this.dialog.open(ImpersonatePopupComponent, {
            width: '27.5em'
          });
          dialogRef.afterClosed().subscribe((result) => {
            if(result) {
              this.store$.dispatch(loginAs({login: result}));
            }
          });
          
        })
      ),
    { dispatch: false }
  );  
}
