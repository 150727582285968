import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputType, TcFilterTypes } from '@tc/abstract';
import { FilterTypesEnum } from '@tc/abstract';
import { TcFilterItem } from '@tc/abstract';
import { TcFormlyComponent } from '@tc/core';

@Injectable({
  providedIn: 'root',
})
export class TcFilterService {
  /**
   * @ignore
   */
  constructor(private readonly router: Router) {}

  /**
   * Add filters to the current URL
   * @param filters
   */
  public updateUrl(filters: TcFilterItem[]) {
    let queryParams = {};

    filters.forEach((filter) => {
      queryParams[filter.key] = filter.value;
    });

    this.router.navigate([], {
      queryParams,
      replaceUrl: true,
    });
  }

  /**
   * Get filterType of a FormlyFieldConfig
   */
  getFormlyFieldFilterType(fieldDef: FormlyFieldConfig) {
    return fieldDef.templateOptions?.filterType
      ? fieldDef.templateOptions?.filterType
      : FilterTypesEnum.Contains;
  }

  /**
   * Get type of a FormlyFieldConfig
   */
  getFormlyFieldType(fieldDef: FormlyFieldConfig) {
    return fieldDef.templateOptions?.fieldType
      ? fieldDef.templateOptions?.fieldType
      : TcFilterTypes.text;
  }

  /**
   * Get the filters items from the form configuration
   * @param fields
   * @returns TcFilterItem[]
   */
  public getDefaultFilterItems(fields: any[]) {
    let fieldDefs = this.getFormlyFieldDefinitions(fields);

    const filters: TcFilterItem[] = [];

    fieldDefs.forEach((fieldDef: FormlyFieldConfig) => {
      const filterType = this.getFormlyFieldFilterType(fieldDef);
      const type = this.getFormlyFieldType(fieldDef);
      const defaultValue = fieldDef.defaultValue;
      const key = fieldDef.key.toString();
      const filterOn = fieldDef?.templateOptions?.filterOn;
      const filterMultiWord = fieldDef?.templateOptions?.filterMultiWord;
      const filterMultiWordOperator =
        fieldDef?.templateOptions?.filterMultiWordOperator;

      if (defaultValue != undefined) {
        let filter: TcFilterItem = {
          key,
          filterType,
          type,
          value: defaultValue,
          ...(filterOn ? { filterOn } : {}),
          ...(filterMultiWord ? { filterMultiWord } : {}),
          ...(filterMultiWordOperator ? { filterMultiWordOperator } : {}),
        };

        filters.push(filter);
      }
    });

    return filters;
  }

  /**
   * Get the filter item based on urlKey and urlValue
   * @param fields
   * @param urlKey
   * @param urlValue
   * @returns TcFilterItem
   */
  public getFilterItemFromUrl(fields, urlKey, urlValue): TcFilterItem {
    let fieldDefs = this.getFormlyFieldDefinitions(fields);

    const fieldDef = fieldDefs.find((fieldDef) => fieldDef.key === urlKey);

    const filterType = this.getFormlyFieldFilterType(fieldDef);
    const type = this.getFormlyFieldType(fieldDef);
    const filterOn = fieldDef?.templateOptions?.filterOn;
    const filterMultiWord = fieldDef?.templateOptions?.filterMultiWord;
    const filterMultiWordOperator =
      fieldDef?.templateOptions?.filterMultiWordOperator;

    const filter: TcFilterItem = {
      key: urlKey,
      filterType,
      type,
      value: urlValue,
      ...(filterOn ? { filterOn } : {}),
      ...(filterMultiWord ? { filterMultiWord } : {}),
      ...(filterMultiWordOperator ? { filterMultiWordOperator } : {}),
    };

    return filter;
  }

  /**
   * Get all field definition (formly full configuration) flatten into a array.
   * @returns Formly configuration
   */
  getFormlyFieldDefinitions(fields: any[]): FormlyFieldConfig[] {
    let fieldDefs = [];
    const keyArray = this.getKeysFromDef(fields);

    for (let i = 0; i < keyArray.length; i++) {
      const item = keyArray[i];

      for (let j = 0; j < item.length; j++) {
        const row = item[j];

        for (let k = 0; k < row.length; k++) fieldDefs.push(row[k]);
      }
    }

    return fieldDefs;
  }

  /**
   * Recursive method that looks into the field config to find the declared formly configuration.
   * @param fields Part of the config of form (the one containing the formly configuration)
   * @returns Array (not flatten) of matching elements
   */
  getKeysFromDef(fields: any[]): any[] {
    const filterItems = [];

    for (const field in fields) {
      const item = fields[field];

      if (item.hasOwnProperty('fieldGroup')) {
        filterItems.push(this.getKeysFromDef(item.fieldGroup));
      } else {
        filterItems.push(item);
      }
    }

    return [...filterItems];
  }

  /**
   * Gets the filed TcFormlyComponent type
   * @param fieldKey key of the field
   * @param fields fields config
   * @returns TcFormlyComponent type or null in case the key does not exists in the fields
   */
  getTcFormlyInputType(
    fieldKey: string,
    fields: FormlyFieldConfig[]
  ): TcFormlyComponent | null {
    const fieldDefs = this.getFormlyFieldDefinitions(fields);

    const field = fieldDefs.find((field) => field.key === fieldKey);

    return (field?.type as TcFormlyComponent) || null;
  }

  /**
   * Gets the filed TcFormlyComponent type
   * @param fieldKey key of the field
   * @param fields fields config
   * @returns InputType or null in case the key does not exists in the fields
   */
  getInputType(
    fieldKey: string,
    fields: FormlyFieldConfig[]
  ): InputType | null {
    const fieldDefs = this.getFormlyFieldDefinitions(fields);

    const field = fieldDefs.find((field) => field.key === fieldKey);

    return (field?.templateOptions?.type as InputType) || null;
  }
}
