import { Injectable } from '@angular/core';

import {
  Resolve,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { fonctionTypesOptionsLoaded } from '../store/custom.actions';
import { FonctionTypesService } from '../services/fonction-types.service';

@Injectable()
export class FonctionTypesOptionsResolver implements Resolve<any> {
  constructor(
    private readonly store$: Store<any>,
    private readonly fonctionTypesService: FonctionTypesService
  ) {}

  async resolve(
  ): Promise<any> {
    const fonctionTypesOptions = (await this.fonctionTypesService.getAll()).data.map(
      (ft) => {
        return {
          value: ft.codeFonctionType,
          label: ft.fonctionType,
        };
      }
    );
    this.store$.dispatch(fonctionTypesOptionsLoaded({ fonctionTypesOptions: fonctionTypesOptions }));
    return fonctionTypesOptions;
  }
}
