import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  ActionSettingsChangeLanguage,
  TcMenuComponent,
  TcMenuItem,
  TcNavMenuNodes,
  TcAppMenuService,
} from '@tc/core';
import { ConfigKeys } from '../../../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../../../shared/services/config.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserModel } from '../../../../../modules/auth/models/user.model';
import {
  clearMfaOptions,
  impersonate,
  logout,
} from '../../../../../modules/auth/store/auth.actions';
import {
  getAuthenticatedUser,
  getAuthenticationState,
} from '../../../../../modules/auth/store/auth.selectors';
import { ExampleDialogComponent } from '../../../../../modules/examples/components/smart/example-dialog/example-dialog.component';
import { PermissionsService } from '../../../../../services/permissions.service';
import { TcAppState } from '@tc/store';
import { getAppMenuItems } from '../../../../menu/store/menu.selectors';
import { TcHelpDialogComponent } from '@tc/dialog';
import { TcHelpDialogPayload } from '@tc/abstract';
import { getAbilities } from '@tc/permissions';
import { take } from 'rxjs/operators';
import { TcPermissionAction } from '../../../../auth/enums/tc-permission-action.enum';

interface MediaQueryObject {
  mediaQueryList: MediaQueryList;
  mediaQueryListener: () => void;
  mediaQueryClass: string;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  private readonly userMenuItemNames = {
    logout: 'logout',
    impersonate: 'impersonate',
  };
  private readonly logoutMenuItem: TcMenuItem = {
    name: this.userMenuItemNames.logout,
    icon: 'power_settings_new',
  };

  private readonly impersonateMenuItem: TcMenuItem = {
    name: this.userMenuItemNames.impersonate,
    icon: 'group',
  };

  authenticatedUser$: Observable<UserModel>;

  appMenuItems$: Observable<TcNavMenuNodes[]>;

  currentUserMenu: TcMenuComponent;
  @ViewChild('currentUserMenu', { static: true }) set appCurrentUserMenu(
    value: TcMenuComponent
  ) {
    this.currentUserMenu = value;
  }

  /**
   * Subject that is used to transmit to the tc-layout that one of the menu items has been clicked.
   * Thus the sidenav knows to close itself after a menu is clicked when we are on a mobile resolution
   */
  selectedMenuItemSubject: Subject<void> = new Subject<void>();

  /**
   * MediaQueryList that is used in order to know when the resolution reaches the mobile breakpoint
   */
  mobileQuery: MediaQueryList;

  mediaQueryList: Array<MediaQueryObject> = [];

  /**
   * Listener that is used by mobileQuery for when the resolution reaches the mobile breakpoint.
   * Only declared so that it can be removed in the ngOnDestroy method
   */
  private _mobileQueryListener: () => void;

  /**
   * Boolean that is toggled in the _mobileQueryListener whenever the resolution reaches
   * the mobile breakpoint
   */
  mobileResolution = false;

  /**
   * Object with the responsive configurations sent via the router.
   * It can be found in the configuration file of the custom app, assets/config.json
   * It is also passed down to the tc-layout component
   * Current options:
   *  - responsive: Boolen (true || false)
   *  - breakPoint: String ('768px')
   *  - menuIcon: String ('more_vert')
   */
  responsiveOptions;

  /**
   * The logo image that appers on the top-left corner
   * the image is expected to be in /app/custom/assets/images/
   */
  logoImageName: string;

  /**
   * Define if logout option should be available or not
   * this has to be defined in config.json
   */
  isLogoutDisabled = false;

  isUserMenuDisabled = true;
  isImpersonation = false;
  hasImpersonateAbility = false;

  /**
   * Help configurations
   */
  helpConfig: {
    enabled: boolean;
    helpIcon: string;
    emailAddress: string;
    mailSubject?: string;
  };

  constructor(
    protected readonly router: Router,
    protected readonly dialog: MatDialog,
    protected readonly store$: Store<TcAppState>,
    protected readonly permissions: PermissionsService,
    protected readonly menuService: TcAppMenuService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private readonly config: ConfigService,
    @Inject(DOCUMENT) private document: any
  ) {}

  openExampleDialog() {
    this.dialog.open(ExampleDialogComponent);
  }

  ngOnInit() {
    this.store$.dispatch(clearMfaOptions());

    this.initAuthenticatedUserData();

    this.isLogoutDisabled = this.config.get(ConfigKeys.logout_disabled);

    this.initUserMenu();
    this.listenChangePermissions();

    if (this.route.snapshot.data.useResponsiveOptions) {
      this.responsiveOptions = this.config.get(
        ConfigKeys.layoutConfig.responsiveOptions
      );

      if (this.responsiveOptions?.responsive) {
        const setMobileResolution = () => {
          this.mobileResolution = this.mobileQuery.matches;
          this.mobileResolution
            ? this.document.body.classList.add('responsive')
            : this.document.body.classList.remove('responsive');
        };

        this.mobileQuery = this.media.matchMedia(
          `(max-width: ${this.responsiveOptions.breakPoint})`
        );
        setMobileResolution();

        this._mobileQueryListener = () => {
          setMobileResolution();
          this.changeDetectorRef.detectChanges();
        };
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);

        for (const [key, value] of Object.entries(
          this.responsiveOptions.deviceBreakPoints
        )) {
          // Convert camelCase to dish
          const mediaQueryClass = key.replace(
            /[A-Z]/g,
            (upperCaseChar) => '-' + upperCaseChar.toLowerCase()
          );

          // Create media query listener
          this.createMediaQuery(
            mediaQueryClass,
            (value as any).min,
            (value as any).max
          );
        }
      }
    }

    this.logoImageName = this.config.get(ConfigKeys.layoutConfig.logoImageName);

    this.helpConfig = this.config.get(ConfigKeys.helpConfig);
  }

  public onSelectedNavMenuItem(menuItem: TcNavMenuNodes) {
    const route = (menuItem as any)?.route;
    if (route) {
      this.selectedMenuItemSubject.next();
    }
  }

  /**
   * Creates and stores media query listeners(for removing EventListeners) that add
   * css classes on the body depending on the size of the screen
   * @param mediaQueryClass example: `'small-device'`
   * @param minResolution example: `500px`
   * @param maxResolution example: `700px`
   */
  createMediaQuery(
    mediaQueryClass: string,
    minResolution?: string,
    maxResolution?: string
  ) {
    const setResolutionClass = () => {
      mediaQueryList.matches
        ? this.document.body.classList.add(mediaQueryClass)
        : this.document.body.classList.remove(mediaQueryClass);
    };

    let mediaQueryInterval;

    // Note: The app works fine even with this bug but it is still a bug.
    // FIXME: TODO - there is a bug with matchMedia that forces me to add 1px otherwise there is
    //               a gap of 2 pixels in the query for some reason
    if (maxResolution)
      maxResolution = `${Number(maxResolution.split('px')[0]) + 1}px`;

    if (minResolution) {
      mediaQueryInterval = `(min-width: ${minResolution})`;

      if (maxResolution) {
        mediaQueryInterval = `${mediaQueryInterval} and (max-width: ${maxResolution})`;
      }
    } else if (maxResolution) {
      mediaQueryInterval = `(max-width: ${maxResolution})`;
    }

    const mediaQueryList = this.media.matchMedia(mediaQueryInterval);

    setResolutionClass();

    const mediaQueryListener = () => {
      setResolutionClass();
      this.changeDetectorRef.detectChanges();
    };

    mediaQueryList.addEventListener('change', mediaQueryListener);

    this.mediaQueryList.push({
      mediaQueryList,
      mediaQueryListener,
      mediaQueryClass,
    });
  }

  private listenChangePermissions() {
    this.authenticatedUser$.subscribe(async (user) => {
      if (!user) {
        return;
      }
      this.initSidebarMenu();
    });

    this.store$
      .pipe(select(getAuthenticationState))
      .subscribe(async (authState) => {
        this.isImpersonation = !!authState.loginAs;
        this.initUserMenu();
      });

    this.store$.pipe(select(getAbilities)).subscribe(async (abilities) => {
      if (!abilities) {
        return;
      }
      this.hasImpersonateAbility = abilities.can(
        TcPermissionAction.Impersonate,
        null
      );
      this.initUserMenu();
    });
  }

  private initUserMenu() {
    this.currentUserMenu.items = [];

    // Display logOut menu if enabled in config or we have impersonation
    if (!this.isLogoutDisabled || this.isImpersonation) {
      this.currentUserMenu.items.push(this.logoutMenuItem);
    }
    
    if (this.hasImpersonateAbility) {
      this.currentUserMenu.items.push(this.impersonateMenuItem);
    }

    this.isUserMenuDisabled = this.currentUserMenu.items.length === 0;

    this.currentUserMenu.onMenuItemClick = (item: TcMenuItem) => {
      if (item.name === this.userMenuItemNames.logout) {
        this.store$.dispatch(logout());
      }
      if (item.name === this.userMenuItemNames.impersonate) {
        this.store$.dispatch(impersonate());
      }
    };
  }

  private async initSidebarMenu() {
    this.appMenuItems$ = this.store$.pipe(select(getAppMenuItems));
  }

  private initAuthenticatedUserData() {
    this.authenticatedUser$ = this.store$.pipe(select(getAuthenticatedUser));
  }

  changeLang(language: string) {
    this.store$.dispatch(new ActionSettingsChangeLanguage({ language }));
  }

  ngOnDestroy(): void {
    if (this.responsiveOptions?.responsive) {
      this.mobileQuery?.removeEventListener(
        'change',
        this._mobileQueryListener
      );
      this.document.body.classList.remove('responsive');

      this.mediaQueryList.forEach((mediaQuery) => {
        mediaQuery.mediaQueryList?.removeEventListener(
          'change',
          mediaQuery.mediaQueryListener
        );
        this.document.body.classList.remove(mediaQuery.mediaQueryClass);
      });
    }
  }

  openHelp() {
    // Open the help dialog
    this.dialog.open(TcHelpDialogComponent, {
      maxWidth: '90vw',
      data: {
        mailToEmail: this.helpConfig?.emailAddress,
        mailToSubject: this.helpConfig?.mailSubject,
      } as TcHelpDialogPayload,
    });
  }
}
