import { Component } from '@angular/core';
import { TcDumbComponent } from '@tc/abstract';

/**
 * Displays a currently empty footer
 */
@Component({
  selector: 'tc-dialog-footer',
  templateUrl: './tc-dialog-footer.component.html',
  styleUrls: ['./tc-dialog-footer.component.scss'],
})
export class TcDialogFooterComponent extends TcDumbComponent {}
