import { Inject, Injectable, isDevMode } from '@angular/core';
import { TcService } from '@tc/abstract';
import {
  breeze,
  DataService,
  EntityManager,
  EntityQuery,
  Entity,
  EntityKey,
  EntityState,
  MergeStrategy,
} from 'breeze-client';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderODataAdapter } from 'breeze-client/adapter-uri-builder-odata';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';
import { HttpClient } from '@angular/common/http';
import ObjectID from 'bson-objectid';
import { IConfigService } from '@tc/config';
import { CONFIG_SERVICE } from '@tc/config';

@Injectable({
  providedIn: 'root',
})
export class TcBreezeService extends TcService {
  protected masterManager: EntityManager;

  /**
   * @ignore
   */
  constructor(
    public http: HttpClient,
    @Inject(CONFIG_SERVICE) public config: IConfigService
  ) {
    super();
    // Configure Breeze adapters
    ModelLibraryBackingStoreAdapter.register();
    UriBuilderODataAdapter.register();
    AjaxHttpClientAdapter.register(http);

    // Mongo does not have yet a data adapter in breeze-client. This file is a javascript adapter from breezejs repository, purged from the non working elements.
    // Repo : https://github.com/Breeze/breeze.js/blob/master/src/breeze.dataService.mongo.js
    require('./breeze.dataService.mongo.js');

    // Configure breeze to initialize Mongo data adapter
    breeze.config.initializeAdapterInstance('dataService', 'mongo', true);
    const dataService = new DataService({
      serviceName: config.get('API_BASE_PATH') + '/breeze/',
      hasServerMetadata: true,
    });
    this.masterManager = new EntityManager({ dataService });
  }

  /**
   * Return an valid id for mongo based on system time
   * @returns An BSON ObjectId usable in mongo.
   */
  public getObjectId() {
    return new ObjectID();
  }

  /**
   * Get a new manager from breeze
   */
  public async getEntityManager(): Promise<EntityManager> {
    if (this.masterManager.metadataStore.isEmpty()) {
      await this.masterManager.fetchMetadata();
    }
    return this.masterManager;
  }

  public async hasMetadata(collection: string): Promise<boolean> {
    if (this.masterManager.metadataStore.isEmpty()) {
      await this.masterManager.fetchMetadata();
    }
    return this.masterManager.metadataStore.hasMetadataFor(collection);
  }

  /**
   * Create an entity inside the entity manager. By default, it will consider that the data must be added to database at next sync.
   * @param collection The collection name
   * @param data The default values of the data
   * @param state The default state of the entity
   * @returns
   */
  public async createEntity(
    collection: string,
    data,
    state: EntityState = EntityState.Added,
    mergeStategy?: MergeStrategy
  ): Promise<Entity> {
    const manager = await this.getEntityManager();
    return manager.createEntity(collection, data, state, mergeStategy);
  }

  /**
   * Return an entity from the entityManager based on his primary id
   * @param collection Name of the collection
   * @param id Identifier of the collection
   */
  public async getEntityById(collection: string, id: string): Promise<Entity> {
    const manager = await this.getEntityManager();
    const type = manager.metadataStore.getAsEntityType(collection);
    const key = new EntityKey(type, id);
    return manager.getEntityByKey(key);
  }

  /**
   * Send to the database the objects modified in EntityManager
   */
  public async sync() {
    const manager = await this.getEntityManager();
    try {
      await manager.saveChanges();
    } catch (error) {
      // Log to console the entities error because the entityErrors
      // is lost before reaching the tc-error-handler somewhere.
      if (isDevMode() && error.entityErrors) {
        console.error('Full entityErrors object ', error.entityErrors);
        error.entityErrors.forEach((error) => {
          console.error(error?.errorMessage, error);
        });
      }

      // Preserve the initial behaviour
      throw error;
    }
  }
}
