import { Injectable } from '@angular/core';
import { TcTranslateService } from '@tc/core';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  getTcData,
  NgRxTcDataState,
} from '@tc/data-store';
import { selectByKey } from '@tc/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { select, Store } from '@ngrx/store';
import { hasValue } from '@tc/utils';
import { TcBreezeService } from '@tc/breeze';
import { EntityQuery, FilterQueryOp, Predicate } from 'breeze-client';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  /**
   *  Data store
   */
  dataStore$: Observable<NgRxTcDataState>;

  TABLE_STYLE = 'table_style';
  SUBTITLE_STYLE = 'subtitle_style';

  constructor(
    private readonly store$: Store<any>,
    private readonly translate: TcTranslateService,
    private breeze: TcBreezeService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  buildPdf(pdfContent) {
    const pdfDef = {
      pageSize: 'A4',
      pageMargins: [40, 40, 40, 60],
      content: pdfContent,
      footer: (currentPage, pageCount) => {
        return {
          text: currentPage.toString() + '/' + pageCount,
          alignment: 'right',
          margin: [0, 10, 40, 60],
        };
      },
      styles: {
        [this.SUBTITLE_STYLE]: {
          fontSize: 16,
          bold: true,
          margin: [0, 5, 0, 5],
        },
        [this.TABLE_STYLE]: {
          margin: [0, 10, 0, 10],
          fontSize: 10,
        },
      },
    };
    pdfMake.createPdf(pdfDef).open();
  }

  async getInteractionsData(prefix: string, id: string) {
    const storeKey = `${prefix}-${id}-interactions-panel`;

    const data = await selectByKey(getTcData, this.dataStore$, storeKey)
      .pipe(take(1))
      .toPromise();

    return data;
  }

  getPresence(interaction: any) {
    const presence = [];

    (interaction?.presence as any[]).forEach((p) => {
      const nom = `${p?.interlocuteur?.prenom ?? ''} ${
        p?.interlocuteur?.nom ?? ''
      }`;

      const titreCatreVisite = p?.interlocuteur?.titreCarteVisite;
      const organisationName = p?.organisme?.nom;

      const array = [titreCatreVisite, organisationName];
      let socialStatus = array.filter((n) => !!n).join(' - ');
      socialStatus = socialStatus.length > 0 ? `(${socialStatus})` : '';

      presence.push([
        {
          text: [
            { text: this.wrapText(nom), alignment: 'left' },
            {
              text: '\n' + socialStatus,
              fontSize: 9,
              italics: true,
            },
          ],
        },
      ]);
    });

    return presence;
  }

  transformHTML(value: any): string {
    const temp = document.createElement('div');
    temp.innerHTML = value;
    return temp.textContent || temp.innerText || '';
  }

  // allow breaking after any character to be sure that text will be always inside the cell
  wrapText(text: string) {
    return text?.split('').join('\u200b');
  }

  async printInterlocuteur(data: any) {
    const pdfContent = [];

    const addressArray = [
      data?.localisation?.adresse,
      data?.localisation?.codePostal,
      data?.localisation?.ville,
      data?.localisation?.departement?.departement,
    ];
    const address = addressArray.filter((n) => !!n).join(', ');

    pdfContent.push({
      style: this.TABLE_STYLE,
      table: {
        widths: [65, 65, '*', 100, 100],
        body: [
          [
            {
              text: this.wrapText(data?.nom ?? ''),
            },
            {
              text: this.wrapText(data?.prenom ?? ''),
            },
            {
              text: this.wrapText(data?.titreCarteVisite ?? ''),
            },
            {
              text: this.wrapText(data?.telephonePortable ?? ''),
            },
            {
              text: this.wrapText(data?.email ?? ''),
            },
          ],
          [
            {
              text: `${this.translate.instant('rank')}: ${data?.rang ?? 'N/A'}`,
            },
            {
              text: `${this.translate.instant('cdf')}: ${
                data?.utilisateurChefFile?.adInitiales ?? ''
              }`,
            },
            {
              text: '',
            },
            {
              text: '',
            },
            {
              text: '',
            },
          ],
        ],
      },
    });

    pdfContent.push({
      style: this.TABLE_STYLE,
      table: {
        widths: [140, '*'],
        body: [
          [
            {
              text: this.wrapText(data?.organisme?.nom ?? ''),
            },
            {
              text: address.length > 0 ? this.wrapText(address) : '',
            },
          ],
        ],
      },
    });

    const interactionsData = await this.getInteractionsData(
      'interlocuteur',
      data?._id
    );

    pdfContent.push({
      text: this.translate.instant('interactions'),
      style: this.SUBTITLE_STYLE,
    });

    (interactionsData as any[]).forEach((i) => {
      pdfContent.push({
        style: this.TABLE_STYLE,
        table: {
          widths: [65, 65, 30, '*'],
          body: [
            [
              {
                text: moment(i.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                alignment: 'center',
              },
              {
                text: this.translate.instant(
                  `interaction-panel-item.type.${i.type}`
                ),
                alignment: 'center',
              },
              {
                text: this.translate.instant('object'),
                alignment: 'right',
              },
              {
                text: this.wrapText(i.intitule),
              },
            ],
            [
              {
                table: {
                  body: this.getPresence(i),
                },
                layout: {
                  defaultBorder: false,
                },
                colSpan: 2,
              },
              '',
              {
                text: i.compteRendu
                  ? this.wrapText(this.transformHTML(i.compteRendu))
                  : '',
                colSpan: 2,
              },
              '',
            ],
          ],
        },
      });
    });

    this.buildPdf(pdfContent);
  }

  async getInterlocuteursForOrganisme(_id: string) {
    const manager = await this.breeze.getEntityManager();

    let query = EntityQuery.from('interlocuteurs');

    const predicate = new Predicate('organisme._id', FilterQueryOp.Equals, _id);

    query = query
      .where(predicate)
      .select(
        'organisme,nom,prenom,titreCarteVisite,email,telephonePortable,rang,utilisateurChefFile'
      )
      .orderBy('nom');

    const queryResult = await manager.executeQuery(query);

    return queryResult.results;
  }
  async printOrganisme(data: any) {
    const pdfContent = [];

    pdfContent.push({
      style: this.TABLE_STYLE,
      table: {
        widths: ['*', 200],
        body: [
          [
            {
              text: this.wrapText(data?.nom ?? ''),
            },
            {
              text: this.wrapText(data?.compteClef?.compteClef ?? ''),
            },
          ],
          [
            {
              text: '',
            },
            {
              text: this.wrapText(data?.groupOrganisme?.nom ?? ' '), // hack: if organisme group is not defined, display empty space in order to render the entire cell
            },
          ],
        ],
      },
    });

    pdfContent.push({
      text: this.translate.instant('interlocuteurs'),
      style: this.SUBTITLE_STYLE,
    });

    const interlocuteurs = await this.getInterlocuteursForOrganisme(data._id);

    // header row
    const interlocuteursTableData: any = [
      [
        { text: this.translate.instant('nom'), bold: true },
        { text: this.translate.instant('prenom'), bold: true },
        { text: this.translate.instant('titreCarteVisite'), bold: true },
        { text: this.translate.instant('email'), bold: true },
        { text: this.translate.instant('telephonePortable'), bold: true },
        { text: this.translate.instant('rank'), bold: true },
        { text: this.translate.instant('cdf'), bold: true },
      ],
    ];
    (interlocuteurs as any[]).forEach((i) => {
      interlocuteursTableData.push([
        { text: this.wrapText(i.nom ? (i.nom as string).toUpperCase() : '') },
        { text: this.wrapText(i.prenom ?? '') },
        { text: this.wrapText(i.titreCarteVisite ?? '') },
        { text: this.wrapText(i.email ?? '') },
        { text: this.wrapText(i.telephonePortable ?? '') },
        { text: this.wrapText(i.rang ?? '') },
        { text: this.wrapText(i.utilisateurChefFile?.adInitiales ?? '') },
      ]);
    });

    pdfContent.push({
      style: this.TABLE_STYLE,
      table: {
        widths: [65, 65, '*', 80, 90, 25, 30],
        body: interlocuteursTableData,
      },
    });

    pdfContent.push({
      text: this.translate.instant('interactions'),
      style: this.SUBTITLE_STYLE,
    });

    const interactionsData = await this.getInteractionsData(
      'organisme',
      data?._id
    );

    (interactionsData as any[]).forEach((i) => {
      pdfContent.push({
        style: this.TABLE_STYLE,
        table: {
          widths: [65, 65, 30, '*'],
          body: [
            [
              {
                text: this.wrapText(
                  this.transformHTML(
                    i.contactFaitParUtilisateur?.adInitiales ?? ''
                  )
                ),
                alignment: 'center',
                colSpan: 2,
              },
              '',
              {
                text: this.translate.instant('object'),
                alignment: 'right',
              },
              {
                text: this.wrapText(i.intitule),
              },
            ],
            [
              {
                table: {
                  body: [
                    [
                      {
                        table: {
                          widths: [65, 65],
                          body: [
                            [
                              {
                                text: moment(i.date, 'YYYY-MM-DD').format(
                                  'DD/MM/YYYY'
                                ),
                                alignment: 'center',
                              },
                              {
                                text: this.translate.instant(
                                  `interaction-panel-item.type.${i.type}`
                                ),
                                alignment: 'center',
                              },
                            ],
                          ],
                        },
                        margin: [-10, -6, 0, 0],
                      },
                    ],
                    [
                      {
                        table: {
                          body: this.getPresence(i),
                        },
                        margin: [-5, 0, 0, 0],
                        layout: {
                          defaultBorder: false,
                        },
                      },
                    ],
                  ],
                },
                layout: {
                  defaultBorder: false,
                },
                colSpan: 2,
              },
              '',
              {
                text: i.compteRendu
                  ? this.wrapText(this.transformHTML(i.compteRendu))
                  : '',
                colSpan: 2,
              },
              '',
            ],
          ],
        },
      });
    });

    this.buildPdf(pdfContent);
  }
}
