import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TcPanelListConfig } from '@tc/panel-list';
import {
  FilterTypesEnum,
  TcConfigTypes,
  TcDataProviderType,
  ListOrder,
  TcFilterTypes,
} from '@tc/abstract';
import { TcSmartListComponent } from '@tc/advanced-components';
import { Store } from '@ngrx/store';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFilter,
  TcFormlyComponent,
  TcToggleComponent,
} from '@tc/core';

import { TranslateService } from '@ngx-translate/core';
import { emptyTcData, loadTcData } from '@tc/data-store';
@Component({
  selector: 'app-interactions-panel',
  templateUrl: './interactions-panel.component.html',
  styleUrls: ['./interactions-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InteractionsPanelComponent
  extends TcSmartListComponent
  implements OnInit
{
  @Input() organismeId: string;

  @Input() interlocuteurId: string;

  @Input() hasFilter: boolean;

  @Input() emptyIfNoIdProvided: boolean;

  storeKey = 'interactions-panel';
  
  sortOrderKey = 'createdOn';
 
  sortToggleItem : TcFilter = {
    key: 'interaction.date',
    type: TcFilterTypes.toggle,
    options: {
      default: false,
      offLabel: this.translateService.instant('interactions-panel.labels.toggle-date-created'),
      onLabel: this.translateService.instant('interactions-panel.labels.toggle-date-interaction')
    }
  }

  panelConfig: TcPanelListConfig = {
    configType: TcConfigTypes.TcPanelList,
    storeKey: this.storeKey,
    dataProvider: {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'interactions',
      fields:
        'codeInteraction,intitule,date,type,compteRendu,contactFaitParUtilisateur,autresParticipantsAlgoe,pieceJointe,presence',
      sortOrder: {
        key: this.sortOrderKey,
        order: ListOrder.Desc,
      },
    },
    itemSize: 400, //NOTE: also update .scss when updating this
    component: 'InteractionsPanelItemComponent',
  }

  constructor(
    public readonly store$: Store<any>,
    private readonly translateService: TranslateService
  ) {
    super(store$);
    this.listConfig = this.panelConfig;
  }

  ngOnInit() {
    if (this.organismeId) {
      this.storeKey = `organisme-${this.organismeId}-interactions-panel`;

      this.listConfig.storeKey = this.storeKey;

      this.listConfig.dataProvider.filter = {
        filters: [
          {
            key: 'presence.organisme._id',
            value: this.organismeId,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      };
    } else if (this.interlocuteurId) {
      this.storeKey = `interlocuteur-${this.interlocuteurId}-interactions-panel`;

      this.listConfig.storeKey = this.storeKey;

      this.listConfig.dataProvider.filter = {
        filters: [
          {
            key: 'presence.interlocuteur._id',
            value: this.interlocuteurId,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      };
    } else if (this.emptyIfNoIdProvided) {
      this.listConfig.dataProvider.filter = {
        filters: [
          {
            key: 'presence.organisme._id',
            value: '00000000-0000-0000-0000-000000000000', // We are using Guid.Empty instead of null or undefined because some objects have null as value in database
            filterType: FilterTypesEnum.Equal,
          },
        ],
      };
    }

    if (this.hasFilter) {
      const fields = [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: 'presence.interlocuteur._id',
                  type: TcFormlyComponent.FormlyInput,
                  hide: true,
                  templateOptions: {
                    filterType: FilterTypesEnum.Equal,
                  },
                }),
                formlyControl({
                  key: 'presence.interlocuteur.nom',
                  type: TcFormlyComponent.FormlyInput,
                  templateOptions: {},
                  xxlColSpan: 3,
                }),
              ],
            }),
          ],
          colSpan: 12,
        }),
      ];

      this.listConfig.filterConfig = {
        configType: TcConfigTypes.TcFilter,
        storeKey: this.storeKey,
        fields,
        isPersistant: false,
      };
    }

    super.ngOnInit();
  }

  sortToggleChange(toggleValue: boolean) {
    if (toggleValue) {
      this.sortOrderKey = 'date';
    } else {
      this.sortOrderKey = 'createdOn';
    }
    
    this.store$.dispatch(
      emptyTcData({
        storeKey: this.storeKey,
      })
    );
    
    this.store$.dispatch(
      loadTcData({
        storeKey: this.storeKey,
        sort: {
          key: this.sortOrderKey,
          order: ListOrder.Desc
        }
      }) 
    );
  } 
}
