import { createAction, props } from '@ngrx/store';
import { BaseCallbackTcStorePayload } from '@tc/abstract';
import {
  InitTcGridPayload,
  InsertTcGridEmptyRowPayload,
  LoadTcGridDataPayload,
  LoadTcGridDataPayloadSuccess,
  LoadTcGridMoreDataPayload,
  LoadTcGridMoreDataPayloadSuccess,
  openTcGridConfirmPopupPayload,
  OpenTcGridDetailsPopupPayload,
  RefreshTcGridDataPayload,
  RefreshTcGridDataSuccessPayload,
  SetTcGridColumnsPayload,
  SetTcGridDetailsDialogIdPayload,
  UpdateTcGridRowPayload,
  UpdateTcGridRowPayloadSuccess,
} from './tc-grid-payload';

/**
 * the prefix for all the tc grid actions
 */
export const TC_GRID_ACTIONS_PREFIX: string = '[tc-grid]';

/**
 * the action to init tc grid
 */
export const initTcGridStore = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Init`,
  props<InitTcGridPayload>()
);

/**
 * the action to set tc grid columns
 */
export const setTcGridColumns = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Set Columns`,
  props<SetTcGridColumnsPayload>()
);

/**
 * the action to trigger load tc grid data
 */
export const loadTcGridData = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Load Data`,
  props<LoadTcGridDataPayload>()
);

/**
 * the action to trigger load tc grid data in store
 * the action will replace data in store
 */
export const loadTcGridDataSuccess = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Load Data Success`,
  props<LoadTcGridDataPayloadSuccess>()
);

/**
 * the action triggered when scrolling into tc grid
 */
export const loadTcGridMoreData = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Load More Data`,
  props<LoadTcGridMoreDataPayload>()
);

/**
 * the action to trigger load tc grid more data in store
 * the action will concat new data with the old data in store
 */
export const loadTcGridMoreDataSuccess = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Load More Data Success`,
  props<LoadTcGridMoreDataPayloadSuccess>()
);

/**
 * update tc grid row
 */
export const updateTcGridRow = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Update Row`,
  props<UpdateTcGridRowPayload>()
);

/**
 * update tc grid row success
 */
export const updateTcGridRowSuccess = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Update Row Success`,
  props<UpdateTcGridRowPayloadSuccess>()
);

/**
 * insert empty row in tc grid.
 * this action is triggered after add button was clicked and a component was not provided, which means an empty line will be added in the grid to be completed
 */
export const insertTcGridEmptyRow = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Insert Empty Row`,
  props<InsertTcGridEmptyRowPayload>()
);

/**
 * this action is triggered after add button was clicked and a component was provided, which means add new item in the grid will be done with popup
 */
export const openTcGridDetailsPopup = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Open Details Popup`,
  props<OpenTcGridDetailsPopupPayload>()
);

/**
 * this action is triggered after delete or deactivate button was clicked and a component was provided,
 * which means delete or deactivate an item in the grid will be done with popup confirmation
 */
 export const openTcGridConfirmPopup = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Open Confirm Popup`,
  props<openTcGridConfirmPopupPayload>()
);

/**
 *  this action is triggered everytime the filter changes
 */
export const refreshTcGridData = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Refresh Grid Data`,
  props<RefreshTcGridDataPayload>()
);

/**
 *  this action is triggered after successfully filtering a dataset
 */
export const refreshTcGridDataSuccess = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Refresh Grid Data Success`,
  props<RefreshTcGridDataSuccessPayload>()
);

/**
 * the action to set tc grid details dialog if
 */
export const setTcGridDetailsDialogId = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Set Tc Grid Details Dialog Id`,
  props<SetTcGridDetailsDialogIdPayload>()
);

/**
 * the action to set tc grid details dialog if
 */
export const closeTcGridDetailsDialog = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Close Tc Grid Details Dialog`,
  props<BaseCallbackTcStorePayload>()
);

/**
 * the action to set tc grid details dialog if
 */
export const closeTcGridDetailsDialogSuccess = createAction(
  `${TC_GRID_ACTIONS_PREFIX}/Close Tc Grid Details Dialog Success`,
  props<BaseCallbackTcStorePayload>()
);
