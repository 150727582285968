import { CustomState, CUSTOM_STATE_KEY } from './custom.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 *  Get FonctionTypesOptions
 */
export const getFonctionTypesOptions = createSelector(
  createFeatureSelector<CustomState>(CUSTOM_STATE_KEY),
  (state: CustomState) => {
    return state.fonctionTypesOptions;
  }
)
