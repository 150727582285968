import { Ability } from '@casl/ability';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { unpackRules } from '@casl/ability/extra';
import {
  DEFAULT_TC_PERMISSIONS_STATE_KEY,
  TcPermissionsState,
} from './tc-permissions.state';

export const getTcPermissionsState = createFeatureSelector<TcPermissionsState>(
  DEFAULT_TC_PERMISSIONS_STATE_KEY
);

const getAbilitiesFromPermissions = (permissions: [string, string][]) => {
  const ability = new Ability();
  if (permissions) {
    ability.update(unpackRules(permissions));
  }
  return ability;
};
export const getAbilities = createSelector(
  getTcPermissionsState,
  (state: TcPermissionsState) => getAbilitiesFromPermissions(state.permissions)
);

export const can = (action, subject) =>
  createSelector(getTcPermissionsState, (state: TcPermissionsState) => {
    return getAbilitiesFromPermissions(state.permissions).can(action, subject);
  });

export const cannot = (action, subject) =>
  createSelector(getTcPermissionsState, (state: TcPermissionsState) => {
    return getAbilitiesFromPermissions(state.permissions).cannot(
      action,
      subject
    );
  });
